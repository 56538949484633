import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from 'react-bootstrap/Image';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from "react";
import { collection, doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { FIREBASE_AUTH, FIREBASE_DB } from "../config/firebaseConfig";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import formatDate from "../scripts/getDate";
import { MdFormatQuote, MdOutlineLibraryAdd, MdOutlineLibraryAddCheck } from "react-icons/md";
import { LiaUserLockSolid, LiaUserClockSolid, LiaUserCheckSolid} from "react-icons/lia";
import toast from "react-hot-toast";

import { FaExternalLinkAlt } from "react-icons/fa";
import Article from "../components/Article";

const News = () => {
    const { id } = useParams();
    const user = FIREBASE_AUTH.currentUser;
    const [news, setNews] = useState<any>(null);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const isFetchedRef = useRef(false);
    const [userData, setUserData] = useState<any>(null);
    useEffect(() => {
        const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'users'), user?.uid), (snapshot) => {
            if (snapshot.exists()) {
                const userData = snapshot.data();
                setUserData(userData);  
                let savedNews = userData?.savedNews;
                const isSaved = savedNews.some((item:any) => item.id.toString() === id);
                setIsSaved(isSaved);
               
            }
        });
        return () => subscriber();
    }, [id]);
    useEffect(() => {
        if (isFetchedRef.current) {
            return;
        }
        const url = 'https://api.worldnewsapi.com/retrieve-news?ids=' + id;
        const apiKey = process.env.REACT_APP_NEWS_API;

        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'x-api-key': String(apiKey)
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log(data.news[0]);
            setNews(data.news[0]);
            isFetchedRef.current = true; // Mark as fetched
        })
        .catch(error => console.log(error.message));
    }, [id]); 


    const handleSave = async () => {
        if (user) {
            const userRef = doc(collection(FIREBASE_DB, 'users'), user.uid);
            const updatedUserData = { ...userData };
            if (isSaved) {
                updatedUserData.savedNews = updatedUserData.savedNews.filter((item:any) => item.id.toString() !== id);
                toast.success('News removed from saved');
               
            } else {
                updatedUserData.savedNews.push({...news,isImported:false});
                toast.success('News saved successfully');
            }
            await setDoc(userRef, updatedUserData);
        }
    }
    
    function citeAPA() {
        
        const formatAuthors = (authors: string[]): string => {
            return authors.map(author => {
                const [firstName, ...lastName] = author.split(' ');
                return `${lastName.join(' ')}, ${firstName.charAt(0)}.`;
            }).join(', ');
        };
        
        const formatDate = (date: string): string => {
            const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString('en-US', options);
        };
        
        const { title, url, publish_date, authors } = news;
        
        const apaCitation = `${formatAuthors(authors)} (${formatDate(publish_date)}). ${title}. Tribune. ${url}`;

        navigator.clipboard.writeText(apaCitation).then(() => {
            toast.success('APA Citation copied to clipboard');
          }).catch(err => {
            console.error('Failed to copy citation:', err);
          });
        
    }
    function citeMLA() {
        const formatAuthorsMLA = (authors: string[]): string => {
            return authors.map(author => {
                const [firstName, ...lastName] = author.split(' ');
                return `${lastName.join(' ')}, ${firstName}.`;
            }).join(', ');
        };
        
        const formatDateMLA = (date: string): string => {
            const [year, month, day] = date.split(' ')[0].split('-');
            const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
            return `${parseInt(day, 10)} ${months[parseInt(month, 10) - 1]} ${year}`;
        };
        
        const { title, url, publish_date, authors } = news;
        
        const mlaCitation = `${formatAuthorsMLA(authors)}. "${title}." Tribune, ${formatDateMLA(publish_date)}, ${url}.`;
        navigator.clipboard.writeText(mlaCitation).then(() => {
            toast.success('APA Citation copied to clipboard');
          }).catch(err => {
            console.error('Failed to copy citation:', err);
          });
    }
    return ( 
        <Container fluid style={{minHeight: '80vh'}}>
             {news === null ? 
             <Col lg={12} md={12} sm={12} xs={12} className="text-center d-flex justify-content-center align-self-center"style={{minHeight:'80vh'}}>
                <Spinner animation="border" role="status" className='align-self-center' variant="success">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Col>:
            <>
            <Row className="d-flex justify-content-center  mb-5">
                
                <Col lg={6} md={8} sm={10} xs={12}>
                    <p className="fs-1 fw-bold">{news.title}</p>

                    <span className="text-muted">Authors</span>
                    <p className="fs-5 text-muted fw-bold">{news.authors.join(', ')}</p>
                    <p className="fs-6 text-muted">{formatDate(news.publish_date)}</p>
                   
                    <div className="d-flex justify-content-end mb-3">
                        
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" size="sm">
                                Cite
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as={Button} onClick={()=>citeAPA()}>APA Citation</Dropdown.Item>
                                <Dropdown.Item as={Button} onClick={()=>citeMLA()}>MLA Citation</Dropdown.Item>
                                
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {news.summary.length > 0 ? <p className="fs-6">{news.summary}</p> : null}
                    <Image src={news.image} fluid className="mx-auto d-block my-3"/>
                    
                    <Article content={news.text} />
                    <div className="d-flex gap-2">
                        {isSaved ? <Button variant="success" onClick={handleSave}><MdOutlineLibraryAddCheck className="mb-1"/> Saved</Button> : <Button variant="success"onClick={handleSave}><MdOutlineLibraryAdd className="mb-1"/> Save</Button>}
                       <Button variant="success" onClick={()=>window.open(news.url, '_blank')}><FaExternalLinkAlt /> Visit Article
                      </Button>
                       
                    </div>
                </Col>
                  
            </Row>

            {/* <Ratings study={study} />
            <Reviews study={study} /> */}
            </>} 
        </Container>
    );
}

export default News;
