
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import FormControl from 'react-bootstrap/esm/FormControl';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/esm/Row';
import { PiMagnifyingGlassPlusBold } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";
import { Card, Nav, OverlayTrigger, Tab, Tooltip, TooltipProps } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ResultStudies from './ResultStudies';
import ResultNews from './ResultNews';
import { RefAttributes } from 'react';
import { JSX } from 'react/jsx-runtime';
type Search ={
    text:string;
    author:string;
    sort:string;
    earliestPublishDate:string;
    latestPublishDate:string;
    offset:number;
}
interface Props {
  search: Search
  setSearch: (search: Search) => void;
  handleSubmit: (e: any) => void;
  show:boolean;
  setShow:(show:boolean)=>void;
  isLoadingStudies:boolean;
  isLoadingNews:boolean;
  dataStudies:any;
  dataNews:any;
  setDataNews:(data:any)=>void;
  setIsLoadingNews:(isLoading:boolean)=>void;
  getNews: (searchParams: any) => void;
}
const Results:React.FC<Props> = ({getNews,search,setSearch,handleSubmit,isLoadingStudies,isLoadingNews,setShow,dataStudies,dataNews,setDataNews,setIsLoadingNews})=> {
    const renderTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & RefAttributes<HTMLDivElement>) => (
        <Tooltip id="button-tooltip" {...props}>
            Advanced Search
        </Tooltip>
      );
    return ( 
        <>
        <Row className="justify-content-center d-flex align-items-center py-2">
          <Col lg={8} md={9} sm={10} xs={11} className="text-center">        
            <Form onSubmit={()=>{}}>
              <Form.Group controlId="searchForm">
                <InputGroup className="mb-3">
                    <FormControl
                        type='text'
                        placeholder="Search for a title..."
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                        value={search.text}
                        onChange={(e)=>setSearch({ ...search, text: e.target.value })}
                        />
                    <Button variant="outline-primary" id="button-addon2" type='submit' onClick={handleSubmit} >
                        <FaSearch />
                    </Button>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <Button variant="primary" id="button-addon2" onClick={()=>setShow(true)}>
                            <PiMagnifyingGlassPlusBold size={20} />
                        </Button>
                    </OverlayTrigger>
                   
                </InputGroup>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
                <Col md={12} className='d-flex justify-content-center mb-2'>
                <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                        <Nav.Link eventKey="first">Studies</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="second" className="custom-success-link">News</Nav.Link>
                    </Nav.Item>
                </Nav>
                </Col>
                <Col md={12} className='d-flex justify-content-center'>
                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <Row className='justify-content-center'>
                            {isLoadingStudies ? <Col className="text-center d-flex justify-content-center"style={{minHeight:'80vh'}}>
                                <Spinner animation="border" role="status" className='align-self-center'>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </Col> : <ResultStudies data={dataStudies}/>}
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                        <Row className='justify-content-center'>
                            {isLoadingNews ? <Col className="text-center d-flex justify-content-center"style={{minHeight:'80vh'}}>
                                <Spinner animation="border" role="status" className='align-self-center'>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </Col> : <ResultNews data={dataNews} getNews={getNews} search={search} setDataNews={setDataNews} setIsLoadingNews={setIsLoadingNews}/>}
                         
                        </Row>
                    </Tab.Pane>
                </Tab.Content>
                </Col>
                
            </Row>
        </Tab.Container>
        
        </>
        
     );
}
 
export default Results;