import {  Button, Card, Modal, OverlayTrigger, Tooltip, TooltipProps } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LuSplitSquareHorizontal } from "react-icons/lu";
import { JSX, RefAttributes, useEffect, useState } from "react";
import SplitFileView from "./SplitFileView";
import { doc, getDoc } from "firebase/firestore";
import { FIREBASE_AUTH, FIREBASE_DB } from "../../config/firebaseConfig";
import { isMobile } from 'react-device-detect';
interface SavedStudiesProps {
    studies: any[];        
}

const SavedStudies: React.FC<SavedStudiesProps> = ({studies}) => {
    const user = FIREBASE_AUTH.currentUser;
    const navigate = useNavigate();
    const [isSelecting, setIsSelecting] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedStudies, setSelectedStudies] = useState<any[]>([]);
    const [studyLinks, setStudyLinks] = useState<any[]>([]);
    const renderTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & RefAttributes<HTMLDivElement>) => ( 
        !isSelecting ? (
        <Tooltip id="button-tooltip" {...props}>
         Select 2 studies to compare
        </Tooltip>
        ):<></>
      );
    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);   
    }
    useEffect(() => {
        const fetchStudyLinks = async () => {
            const links = await Promise.all(
              studies.map(async (study) => {
                const docRef = doc(FIREBASE_DB, 'files', study.id);
                const docSnap = await getDoc(docRef);
      
                if (docSnap.exists()) {
                    let fileObject = docSnap.data();
                    const hasAccess = fileObject.users.some((u: { user_id: string; expirationDate: string }) => 
                        u.user_id === user?.uid && new Date(u.expirationDate) > new Date()
                    );
                    if (hasAccess) {
                     return { study_id: study.id, fileLink: fileObject.fileLink };
                    }
                } else {
                  console.log('No such document!');
                }
                return null;
              })
            );
            console.log(links.filter((link) => link !== null));
            setStudyLinks(links.filter((link) => link !== null));
          };
          if (!isMobile) {
            fetchStudyLinks();       
          }
         
        
    }, [studies, user?.uid]);
    return ( 
        <div>
            {!isMobile &&
                <div className="d-flex justify-content-end">
                    {isSelecting && <p className="text-muted me-2">Select 2 studies</p>}
                    <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                        >
                    {!isSelecting ? <Button size="sm" onClick={()=>{setIsSelecting(true)}} className="mb-2"><LuSplitSquareHorizontal style={{marginBottom:2}}/> Compare</Button>:
                        <Button size="sm" variant="outline-primary" onClick={()=>{
                            if(selectedStudies.length>1){
                                handleShow();
                            }else{
                                setSelectedStudies([]);
                                setIsSelecting(false);
                            }
                        }} className="mb-2"
                    
                        >{selectedStudies.length<2 ? 'Cancel':'Show'}</Button>
                    }
                     </OverlayTrigger>

                </div>
            }
            
            {studies.map((study:any) => (
                <Card className="mb-2" border="primary" key={study.id}>
                    <Card.Header>{study.date}</Card.Header>
                    <Card.Body>
                        <Card.Title>{study.title}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{study.authors.map((author:any) => author.value).join(', ')}</Card.Subtitle>
                        <Card.Text>
                        {study.abstract}
                        </Card.Text>
                        
                        <div className="d-flex justify-content-between">
                            <Card.Link as={Button} onClick={()=>navigate('/study/'+study.id)}>View Study</Card.Link>
                            {isSelecting && !isMobile && (
                                studyLinks && studyLinks.find((link) => link.study_id === study.id) ? (
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => {
                                            if (isSelecting) {
                                                if (selectedStudies.includes(study)) {
                                                    setSelectedStudies(selectedStudies.filter((s) => s !== study));
                                                } else if (selectedStudies.length < 2) {
                                                    setSelectedStudies([...selectedStudies, study]);
                                                }
                                            }
                                        }}
                                        className={`ms-2 ${selectedStudies.includes(study) ? 'glow-button' : ''}`}
                                        active={selectedStudies.includes(study)}
                                    >
                                        {selectedStudies.includes(study) ? 'Selected' : 'Select'}
                                    </Button>
                                ) : (
                                    <p className="text-danger text-small">No Full Access</p>
                                )
                            )}
                                         
                        </div>
                        
                    </Card.Body>
                </Card>
            ))}
            {selectedStudies.length>1 && !isMobile && 
            <Modal show={show} fullscreen={true} onHide={() => handleClose()}>
                <Modal.Header closeButton className="justify-content-center">
                    <Modal.Title className="w-100 text-center">Comparing Studies</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SplitFileView studies={selectedStudies} studyLinks={studyLinks} />
                </Modal.Body>
            </Modal>
            }
           
           
        </div>
     );
}
 
export default SavedStudies;