import { Button, Card, Modal, Table } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from "react";
import { FIREBASE_AUTH, FIREBASE_DB } from "../config/firebaseConfig";
import { collection, doc, getDoc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import toast from "react-hot-toast";

const Moderation = () => {
    const [showRequest, setShowRequest] = useState(false);
    const [requests, setRequests] = useState<any[]|null>(null);
    const [currentRequest, setCurrentRequest] = useState<any|null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const [isRejecting, setIsRejecting] = useState(false);
    const [reason, setReason] = useState('');

    useEffect(() => {
        const q = query(collection(FIREBASE_DB, "adminrequests"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const reqs: { [key: string]: any }[] = [];
                querySnapshot.forEach((doc) => {
                    reqs.push(doc.data());
                });
            console.log(reqs);
            setRequests(reqs);
            });
        return () => unsubscribe();

    }, []);

    const handleRequest = async (isRejected:boolean) => {
        if(currentRequest){
            setIsLoading(true);
            const docRef = doc(FIREBASE_DB, "adminrequests", currentRequest.userId);
            if(isRejected){
                await updateDoc(docRef, {
                    status: 'rejected',
                  }).then(() => {
                    sendNotification(true,currentRequest.userId);
                    toast.success("Request Rejected");
                  });
                  
            }else{
                await updateDoc(docRef, {
                    status: 'approved',
                  }).then(async () => {
                    await setAdmin(currentRequest.userId).then(() => {
                        toast.success("Request Approved");
                        sendNotification(false,currentRequest.userId);
                      });                  
                  });
            }
        }
        setIsLoading(false);
        setShowRequest(false);
        setCurrentRequest(null);
        setIsRejecting(false);
    }
    const setAdmin = async (uid: any) => {
        try {
            const currentUser = FIREBASE_AUTH?.currentUser;
            if (!currentUser) {
                throw new Error('No current user is logged in');
            }
            const token = await currentUser.getIdToken(); // Get the token of the current user (who must have superAdmin claim)
    
            const response = await fetch('https://teksto-back-end.vercel.app/setadmin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Send the authorization token
                },
                body: JSON.stringify({ uid }), // Send the uid of the user you want to set as an admin
            });
    
            const data = await response.json();
            console.log(data); // Output the response
        } catch (error) {
            console.error('Error setting admin:', error);
        }
    };
    const sendNotification = async (isRejected:boolean,uid:string) => {
        const docRef = doc(FIREBASE_DB, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data().notifications);
            let oldNotifications = docSnap.data().notifications;
            const notification_id = uuidv4();
            console.log(notification_id)
            if(isRejected){
                oldNotifications.push({
                    notification_id: notification_id,
                    status:"rejected",
                    title:"Request Rejected",
                    message:"Your request for full admin privileges has been rejected. "+reason,
                    studyID:'',
                    createdAt:new Date().toISOString(),
                    isSeen:false
                });
            }   
            else{
                oldNotifications.push({
                    notification_id: notification_id,
                    status:"approved",
                    title:"Request Approved",
                    message:"Your request for admin privileges has been approved.",
                    studyID:'',
                    createdAt:new Date().toISOString(),
                    isSeen:false
                });
            }
            await updateDoc(docRef, { notifications: oldNotifications });

        } else {
            console.log("No such document!");
        }
    }
    return ( 
        <div className="container-lg" style={{minHeight: '80vh'}}>
            <h1>Admin Requests</h1>
            <Table striped bordered hover>
                    <thead>
                        <tr>      
                            <th>User ID</th>
                            <th>Name</th>
                            <th>Date Issued</th>
                        </tr>
                    </thead>
                    {requests === null ? <p>Loading...</p>
                    :   
                    <tbody>  
                        {requests.map((request, index) => (
                            <tr key={index} onClick={()=>{
                                setShowRequest(true);
                                setCurrentRequest(request);
                                }} style={{ cursor: 'pointer' }}>
                                <td>{request.userId}</td>
                                <td>{request.userName}</td>
                                <td>{request.createdAt?.toDate().toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody> 
                    }
                </Table>
                <Modal show={showRequest} backdrop="static"
                onHide={() => {setShowRequest(false);setCurrentRequest(null);}}
                >
              
                <Modal.Header closeButton>
                <Modal.Title>{currentRequest?.userName}'s Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="">
                    <Card.Body className="d-flex flex-row gap-2">
                    <img
                        // src={currentRequest.user.picture?.includes('googleusercontent') ? currentRequest.user.picture.replace(/s\d+-c/, `s${256}-c`) : currentRequest.user.picture || ''}
                        src={currentRequest?.userPhotoURL}
                        alt="avatar"
                        className="rounded-circle img-fluid"
                        style={{ width: '75px',height:'75px' }}
                    />
                    <div>
                        <h5 className="my-0">{currentRequest?.userName}</h5>             
                        <p className="text-muted my-0">Email: {currentRequest?.userEmail}</p>
                        <p className="my-0">Date Issued: {currentRequest?.createdAt?.toDate().toLocaleDateString()}</p>
                        <p className="my-0">{currentRequest?.text}</p>
                    </div>
                    </Card.Body>
                </Card>
                <Card className="my-3">
                    <Card.Header as="h5">ID</Card.Header>
                    <Card.Img variant="bottom" src={currentRequest?.photoID} />
                </Card>

                {isRejecting &&
                <Card className="my-3">
                    <Card.Header as="h5">Reason for Rejection</Card.Header>
                    <Card.Body>
                        <textarea className="form-control" value={reason} onChange={(e) => setReason(e.target.value)} />
                    </Card.Body>
                    <Card.Footer>
                        <Button variant="danger" disabled={isLoading || reason.length<3 || currentRequest?.status !== 'pending'} onClick={()=>handleRequest(true)}>Reject</Button>
                    </Card.Footer>
                </Card>
                }

                
                </Modal.Body>
                <Modal.Footer>
                <Button variant="danger" disabled={isRejecting || currentRequest?.status !== 'pending'} onClick={()=>setIsRejecting(true)}>
                   Reject
                </Button>
                <Button variant="success" disabled={isLoading || currentRequest?.status !== 'pending'} onClick={()=>handleRequest(false)}>
                    Approve
                </Button>
                </Modal.Footer>
                     
            </Modal>
        </div>
     );
}
 
export default Moderation;