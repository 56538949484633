import { useEffect, useState } from "react";
import { Button, Card, Col, Pagination } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import formatDate from "../../scripts/getDate";
const ResultNews = ({data,search,setDataNews,setIsLoadingNews,getNews}: any) => {
   
    const navigate = useNavigate();
    const [savedData, setSavedData] = useState<any>([]);
   
    function handlePage(offset:number) {
      console.log(offset)
      getNews({ ...search, offset });
    }

    return ( 
        <Col lg={8} style={{ minHeight: '80vh' }} className="d-flex flex-column justify-content-between">
        {data ? (
            <>
                {data.code === 400 && (
                    <h5 className="text-center mt-5 text-muted align-self-center w-100">
                        Search parameter is too short
                    </h5>
                )}
                {data.code === 402 && (
                    <h5 className="text-center text-muted w-100">
                        Error occurred! Contact the developer.
                    </h5>
                )}
                {data.code ===  429 && (
                    <h5 className="text-center text-muted w-100">
                        Too many requests. Please wait a moment.
                        </h5>
                )}
                {data.news?.length > 0 ? (
                    data.news.map((item: any) => (
                        <Card className="mb-2" key={item.id} border="success">
                            <Card.Header className="text-muted text-sm small">
                                {formatDate(item.publish_date)}
                            </Card.Header>
                            <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    {item.authors.join(', ')}
                                </Card.Subtitle>
                                <Card.Text
                                    className="text-truncate text-wrap"
                                    style={{ height: '100px', overflow: 'hidden' }}
                                >
                                    {item.summary || item.text}
                                </Card.Text>
                                <div className="d-flex justify-content-between align-items-end">
                                    <Button variant="success" onClick={() => navigate('/news/' + item.id)}>
                                        View News
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    ))
                ) : (
                    <Col lg={8} md={9} sm={10} xs={11} className="text-center m-auto text-muted h-100 w-100">
                        <h5 className="mt-5">No results found</h5>
                    </Col>
                )}
                {data.available > 0 && (
                    <div className="w-100 d-flex justify-content-center">
                        <Pagination className="custom-pagination">
                            <Pagination.Prev
                                disabled={data.offset + 1 === 1}
                                onClick={() => handlePage(data.offset - 1)}
                            />
                            {Array.from({ length: Math.ceil(data.available / 10) }, (_, i) => i + 1).map((page) => (
                                <Pagination.Item
                                    key={page}
                                    active={page === data.offset + 1}
                                    onClick={() => handlePage(page - 1)}
                                >
                                    {page}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next
                                disabled={data.offset + 1 === Math.ceil(data.available / 10)}
                                onClick={() => handlePage(data.offset + 1)}
                            />
                        </Pagination>
                    </div>
                )}
            </>
        ) : (
            <h5 className="text-center text-muted w-100">Loading</h5>
        )}
    </Col>
    
    );
}
 
export default ResultNews;