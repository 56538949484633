
import React, { useEffect, useState } from 'react';
import { Modal, Button, ListGroup, Badge } from 'react-bootstrap';
import { FIREBASE_AUTH, FIREBASE_DB } from '../../config/firebaseConfig';
import { collection, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
interface NotificationsProps {
    showModal: boolean;
    handleClose: () => void;
    handleShow: () => void;
    setShow: (value: boolean) => void;
    isAdmin: boolean|null;
}
const Notifications:React.FC<NotificationsProps> = ({isAdmin,showModal,handleClose,handleShow,setShow}) => {
    const navigate = useNavigate();
    const user =FIREBASE_AUTH.currentUser;
    const [notifications, setNotifications] = useState<any[]|null>(null);
    useEffect(() => {
        console.log("Is Admin: ", isAdmin); 
        if(isAdmin){
            const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'admin'), 'notifications'), (snapshot) => {
                let notifications = snapshot.data()?.notification_list;
                
                setNotifications(notifications);     
            });
            return () => subscriber();
        }else{
            const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'users'), user?.uid), (snapshot) => {
                let notifications = snapshot.data()?.notifications;
                setNotifications(notifications);     
            });
            return () => subscriber();
        }
        
    }, [isAdmin, user?.uid]);
    const handleviewNotification = async (notification:any) => {
        if(!notification.isSeen) {
            const updatedNotifications = notifications?.map((n:any) => {
                if(n.notification_id === notification.notification_id) {
                    return { ...n, isSeen: true };
                }
                return n;
            });
            if(isAdmin){
                const notificationDocRef = doc(FIREBASE_DB, 'admin', 'notifications');
                await updateDoc(notificationDocRef, { notification_list: updatedNotifications });
            }else{
                const notificationDocRef = doc(FIREBASE_DB, 'users', user?.uid as string);
                await updateDoc(notificationDocRef, { notifications: updatedNotifications });
            }
            
           
        }

        if(notification.studyID.length > 0){
            navigate("/study/"+notification.studyID);
        }else{
            navigate("/"); 
        }
        handleClose();
        setShow(false);
    }
    function formatDate(dateString: string): string {
        
        const date = new Date(dateString);
      
        const month = date.getUTCMonth() + 1; 
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();
      
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
      
        hours = hours % 12;
        hours = hours ? hours : 12; 
      
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
      
        return `${month}/${day}/${year} - ${hours}:${formattedMinutes} ${ampm}`;
      }
    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{isAdmin && 'Admin'} Notifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {notifications && 
                   <>
                    {notifications.length === 0 ? <p className='text-muted'>No notifications</p>
                    :
                        <ListGroup variant='flush'>
                            {[...notifications].reverse().map((notification, index) => (
                                <ListGroup.Item
                                key={index}
                                className="d-flex justify-content-between align-items-start"
                                action
                                onClick={() => handleviewNotification(notification)}
                            >
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold me-2">
                                        {notification.title.split(' ').map((word: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined, idx: React.Key | null | undefined) => {
                                            if (word === "Approved") {
                                                return <span key={idx} className="text-success">{word} </span>;
                                            } else if (word === "Rejected") {
                                                return <span key={idx} className="text-danger">{word} </span>;
                                            } else {
                                                return <span key={idx}>{word} </span>;
                                            }
                                        })}
                                    </div>
                                    {notification.message}
                                    <div className="text-muted">{formatDate(notification.createdAt)}</div>
                                </div>
                                {!notification.isSeen ? (
                                    <Badge pill bg="primary">
                                        New
                                    </Badge>
                                ):
                                    <Badge pill bg="secondary">
                                        Seen
                                    </Badge>
                                }
                            </ListGroup.Item>
                            ))}
                        </ListGroup>
                    }
                   </>
                   
                   }
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Notifications;
