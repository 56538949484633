import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const About = () => {
  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h2>About Us</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lacinia mi non lacus ullamcorper, nec sodales justo condimentum.</p>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <h2>Company History</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod eros nec velit efficitur, sit amet facilisis est hendrerit.</p>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <h2>Mission and Vision</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin auctor urna ut lacus varius, sit amet interdum sapien vehicula.</p>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <h2>Our Team</h2>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="my-2">
          <Card>
            <Card.Img variant="top" src="https://hips.hearstapps.com/hmg-prod/images/lebron-james-of-the-los-angeles-lakers-at-crypto-com-arena-news-photo-1721932781.jpg?crop=0.753xw:1.00xh;0.0952xw,0&resize=640:*" />
            <Card.Body>
              <Card.Title>Team Member 1</Card.Title>
              <Card.Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="my-2">
          <Card>
            <Card.Img variant="top" src="https://via.placeholder.com/150" />
            <Card.Body>
              <Card.Title>Team Member 2</Card.Title>
              <Card.Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="my-2">
          <Card>
            <Card.Img variant="top" src="https://via.placeholder.com/150" />
            <Card.Body>
              <Card.Title>Team Member 3</Card.Title>
              <Card.Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
