import { Button, ButtonGroup, Card, Dropdown, DropdownButton, Form, InputGroup, Modal, Table, Pagination } from "react-bootstrap"; // Import Pagination
import { useEffect, useState } from "react";
import { FIREBASE_AUTH } from "../config/firebaseConfig";
import { FaSearch } from "react-icons/fa";
import toast from "react-hot-toast";

const AllUsers = () => {
  const currentUser = FIREBASE_AUTH?.currentUser;
  const [showRequest, setShowRequest] = useState(false);
  const [users, setUsers] = useState<any[]|null>(null);
  const [filteredUsers, setFilteredUsers] = useState<any[]|null>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [sort, setSort] = useState<string>('all');
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10; // Set number of users per page

  useEffect(() => {
    fetchUsers();
  }, [currentUser]);
  
  useEffect(() => {
    if(!users) return;
    let resultUsers = users.filter((user) => {
      if(sort === 'all') return true;
      if(sort === 'enabled') return !user.isDisabled;
      if(sort === 'disabled') return user.isDisabled;
      if(sort === 'admins') return user.isAdmin;
      if(sort === 'nonadmins') return !user.isAdmin;
      return true;
    });
    
    if(search){
      resultUsers = resultUsers.filter((user) => {
        return user.displayName.toLowerCase().includes(search.toLowerCase());
      });
    }
    setFilteredUsers(resultUsers);
    setCurrentPage(1); // Reset to first page when users are filtered
  }, [search, sort, users]);

  const fetchUsers = async () => {
    try {
      console.log("Fetching users...");
      if (!currentUser) {
        throw new Error("No current user is logged in");
      }
      const token = await currentUser.getIdToken(); // Get the token of the current user (who must have superAdmin claim)

      const response = await fetch("https://teksto-back-end.vercel.app/getusers", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Send the authorization token
        },
      });

      const data = await response.json();
      console.log(data.users); // Output the response
      setUsers(data.users); // Set users data in the state
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const disableUser = async (uid: string) => {
    try {  
      if (!currentUser) {
        throw new Error("No current user is logged in");
      }
      setIsLoading(true);
      const token = await currentUser.getIdToken(); // Get the token of the current user (who must have superAdmin claim)

      const response = await fetch("https://teksto-back-end.vercel.app/disableuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Send the authorization token
        },
        body: JSON.stringify({ uid }), // Send the user ID to be disabled
      });

      const data = await response.json();
      toast.success("User disabled successfully");
      fetchUsers(); 
      setShowRequest(false); // Close the modal
    } catch (error) {
      console.error("Error disabling user:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const enableUser = async (uid: string) => {
    try {  
      if (!currentUser) {
        throw new Error("No current user is logged in");
      }
      setIsLoading(true);
      const token = await currentUser.getIdToken(); // Get the token of the current user (who must have superAdmin claim)

      const response = await fetch("https://teksto-back-end.vercel.app/enableuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Send the authorization token
        },
        body: JSON.stringify({ uid }), // Send the user ID to be disabled
      });

      const data = await response.json();
      toast.success("User enabled successfully");
      fetchUsers();
      setShowRequest(false); // Close the modal
    } catch (error) {
      console.error("Error enabling user:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const makeAdmin = async (uid: string) => {
    try {  
      if (!currentUser) {
        throw new Error("No current user is logged in");
      }
      setIsLoading(true);
      const token = await currentUser.getIdToken(); // Get the token of the current user (who must have superAdmin claim)

      const response = await fetch("https://teksto-back-end.vercel.app/setadmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Send the authorization token
        },
        body: JSON.stringify({ uid }), // Send the user ID to be disabled
      });

      const data = await response.json();
      toast.success("User made admin successfully");
      fetchUsers();
      setShowRequest(false); // Close the modal
    } catch (error) {
      console.error("Error making user admin:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const removeAdmin = async (uid: string) => {
    try {  
      if (!currentUser) {
        throw new Error("No current user is logged in");
      }
      setIsLoading(true);
      const token = await currentUser.getIdToken(); // Get the token of the current user (who must have superAdmin claim)

      const response = await fetch("https://teksto-back-end.vercel.app/removeadmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Send the authorization token
        },
        body: JSON.stringify({ uid }), // Send the user ID to be disabled
      });

      const data = await response.json();
      toast.success("User removed admin successfully");
      fetchUsers();
      setShowRequest(false); // Close the modal
    } catch (error) {
      console.error("Error removing user admin:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowClick = (user: any) => {
    setSelectedUser(user);
    setShowRequest(true);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers?.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil((filteredUsers?.length || 0) / usersPerPage);

  return (
    <div className="container-lg" style={{ minHeight: '80vh' }}>
      <div className="d-flex justify-content-between align-items-center">
        <h1>All Users</h1>
        <div className='d-flex justify-content-between align-items-center'>
          <InputGroup className="mb-3">
            <Form.Control
              type='text'
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Dropdown as={ButtonGroup}>
              <InputGroup.Text id="basic-addon1"> <FaSearch /></InputGroup.Text>
              <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSort('all')} active={sort === 'all'}>All Users</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setSort('enabled')} active={sort === 'enabled'}>Enabled</Dropdown.Item>
                <Dropdown.Item onClick={() => setSort('disabled')} active={sort === 'disabled'}>Disabled</Dropdown.Item>
                <Dropdown.Item onClick={() => setSort('admins')} active={sort === 'admins'}>Admins</Dropdown.Item>
                <Dropdown.Item onClick={() => setSort('nonadmins')} active={sort === 'nonadmins'}>Non-Admins</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        </div>
      </div>

      {!users ? (
        <div className="text-center my-5">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>User ID</th>
                <th>Name</th>
                <th>Joined</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers?.map((user) => (
                <tr
                  key={user.uid}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(user)}
                >
                  <td>{user.uid}</td>
                  <td>{user.displayName}</td>
                  <td>{new Date(user.joinedDate).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination Component */}
          <div className='w-100 d-flex justify-content-center'>
            <Pagination>
              <Pagination.Prev disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} />
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <Pagination.Item key={page} active={page === currentPage} onClick={() => setCurrentPage(page)}>
                  {page}
                </Pagination.Item>
              ))}
              <Pagination.Next disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)} />
            </Pagination>
          </div>
        </>
      )}

      {/* User Details Modal */}
      <Modal show={showRequest} backdrop="static" onHide={() => setShowRequest(false)}>
        {selectedUser && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>User Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card>
                <Card.Body className="d-flex flex-row gap-2">
                  <img
                    src={selectedUser.image || "https://firebasestorage.googleapis.com/v0/b/teksto-2f91c.appspot.com/o/assets%2F1.png?alt=media&token=f00a1313-ffe9-4f6b-81d9-803e99dd94f8"}
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: "75px", height: "75px" }}
                  />
                  <div>
                    <h5 className="my-0">{selectedUser.displayName}</h5>
                    <p className="text-muted my-0">
                      {selectedUser.email}
                      {selectedUser.emailVerified ? (
                        <span className="badge bg-success ms-2">Verified</span>
                      ) : (
                        <span className="badge bg-danger ms-2">Not Verified</span>
                      )}
                    </p>
                    <p className="my-0">Role: {selectedUser.isAdmin ? "Admin" : "User"}</p>
                    <p className="my-0">Account Status: {selectedUser.isDisabled ? "Disabled" : "Active"}</p>
                  </div>
                </Card.Body>
              </Card>
              <Card className="my-3">
                <Card.Header as="h5">Joined Date</Card.Header>
                <Card.Body>{new Date(selectedUser.joinedDate).toUTCString()}</Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowRequest(false)}>Close</Button>
              {selectedUser.isDisabled ? (
                <Button variant="success" disabled={isLoading} onClick={() => enableUser(selectedUser.uid)}>Enable User</Button>
              ) : (
                <Button variant="danger" disabled={isLoading} onClick={() => disableUser(selectedUser.uid)}>Disable User</Button>
              )}
              {!selectedUser.isAdmin && (
                <Button variant="primary" disabled={isLoading} onClick={() => makeAdmin(selectedUser.uid)}>Make Admin</Button>
              )}
              {selectedUser.isAdmin && (
                <Button variant="warning" disabled={isLoading} onClick={() => removeAdmin(selectedUser.uid)}>Remove Admin</Button>
              )}
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AllUsers;