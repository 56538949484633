import { Row,Col, Form, Button, Card,Image, ListGroup, ProgressBar } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { FIREBASE_AUTH, FIREBASE_DB } from "../config/firebaseConfig";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import logActivity from "../scripts/logActivity";
import { User } from "firebase/auth";

const Ratings = ({study,isAdmin,fileLink}: {study: any,isAdmin:boolean|null,fileLink:string|null}) => {
    const user = FIREBASE_AUTH.currentUser;
    const [userRating, setUserRating] = useState<number | undefined>(study.ratings.find((rating: any) => rating.id === user?.uid)?.rating);
    const isRated = study.ratings.some((rating: any) => rating.id === user?.uid);
    const ratings = study.ratings.map((rating: any) => rating.rating);
    const totalRatings = ratings.length;
    const averageRating = ratings.reduce((sum: any, rating: any) => sum + rating, 0) / totalRatings;
    

    const ratingCounts = ratings.reduce((acc: { [x: string]: any; }, rating: string | number) => {
      acc[rating] = (acc[rating] || 0) + 1;
      return acc;
    }, {} as { [key: number]: number });
  
    const getStarColor = (index: number, rating: number) => {
      return index < rating ? '#ffc107' : '#e4e5e9'; // yellow for filled stars, gray for empty stars
    };
    function addRating(rating: number) {
        if (!user) {
            toast.error('You need to be logged in to rate a study');
            return;
        }
        const newRating={
            id: user.uid,
            rating: rating
        }
        const updatedStudy = {
            ...study,
            ratings: [...study.ratings, newRating]
        };
        getDoc(doc(collection(FIREBASE_DB, 'studies'), 'studies_document')).then((dc) => {
            const currentStudies = dc.data()?.studies_items
            const updatedStudies = currentStudies.map((s: any) => s.id === study.id ? updatedStudy : s)
            setDoc(dc.ref, {studies_items: updatedStudies})
            toast.success('Rating submitted! Thank you for your feedback');
        });
        logActivity("Rating", user as User, study.id, "User rated the study " + study.title + " with a rating of " + rating);
        sendNotification(study,rating);
        
    }
    const sendNotification = async (study:any,rating:number) => {
       
        const docRef = doc(FIREBASE_DB, "admin", 'notifications');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
           
            let oldNotifications = docSnap.data().notification_list;
            const notification_id = uuidv4();
            
            oldNotifications.push({
                notification_id: notification_id,
                status:"Rating",
                title:user?.displayName+" has rated the study " + study.title,
                message:user?.displayName+" has rated the study " + study.title + " with a rating of " + rating+".",
                studyID:study.id,
                createdAt:new Date().toISOString(),
                isSeen:false
            });
            await updateDoc(docRef, { notification_list: oldNotifications });

        } else {
            console.log("No such document!");
        }
    }       
    
    return ( 
        <Row className="d-flex justify-content-center mt-5 flex-row-reverse">    
            <Col lg={2} md={8} sm={10} xs={12} className="justify-content-center align-content-center mb-5">
                {!isAdmin && fileLink &&
                <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FaStar size={48} color='#ffc107' />
                </div>
                <h5 className="text-center">Rate this study</h5>
                <div className="d-flex justify-content-center">
                    
                    <FaStar style={{userSelect: 'none',cursor:'pointer'}} size={32} color={userRating && userRating >= 1 ? "#ffc107" : "#e4e5e9"} onClick={()=>isRated ? null : setUserRating(1)}/>
                    <FaStar style={{userSelect: 'none',cursor:'pointer'}} size={32} color={userRating && userRating >= 2 ? "#ffc107" : "#e4e5e9"} onClick={()=>isRated ? null : setUserRating(1)}/>
                    <FaStar style={{userSelect: 'none',cursor:'pointer'}} size={32} color={userRating && userRating >= 3 ? "#ffc107" : "#e4e5e9"} onClick={()=>isRated ? null : setUserRating(3)}/>
                    <FaStar style={{userSelect: 'none',cursor:'pointer'}} size={32} color={userRating && userRating >= 4 ? "#ffc107" : "#e4e5e9"} onClick={()=>isRated ? null : setUserRating(4)}/>
                    <FaStar style={{userSelect: 'none',cursor:'pointer'}} size={32} color={userRating && userRating >= 5 ? "#ffc107" : "#e4e5e9"} onClick={()=>isRated ? null : setUserRating(5)}/>

                </div>
                <div className="d-flex justify-content-center mt-2">
                    {isRated ? <p className="text-center text-muted">You have already rated this study</p>
                    :
                    <Button variant="primary" size="sm" disabled={!userRating} onClick={()=>addRating(userRating as number)}>Submit Rating</Button>
                    }
                    
                </div>
                </>}
                
                
            </Col>
            <Col lg={4} md={8} sm={10} xs={12}>
            <div>
                {study.ratings.length === 0 ? <h5>No ratings yet</h5> 
                : 
                <>
                <div className="d-flex flex-row">
                
                <h5 className="me-2">Average Rating: {averageRating.toFixed(1)}</h5>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                    {[1, 2, 3, 4, 5].map((star, index) => (
                    <FaStar key={index} size={24} color={getStarColor(index, Math.round(averageRating))} />
                    ))}
                </div>
                </div>
                
                <h6>Total Ratings: {totalRatings}</h6>
                
                {[5, 4, 3, 2, 1].map((rating) => (
                    <div key={rating} style={{ marginBottom: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="me-2">{rating}</span>
                        {[1, 2, 3, 4, 5].map((star, index) => (
                        <FaStar key={index} size={16} color={getStarColor(index, rating)} />
                        ))}
                        <span style={{ marginLeft: '10px' }}></span>
                    </div>
                    <ProgressBar
                        now={((ratingCounts[rating] || 0) / totalRatings) * 100}
                        label={ratingCounts[rating] || 0}
                    />
                    </div>
                ))}
                </>
                }
                
            </div>
            </Col>
           
        </Row>
    );
}
 
export default Ratings;