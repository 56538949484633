import React from 'react';

interface ArticleProps {
  content: string;
}

const Article: React.FC<ArticleProps> = ({ content }) => {
  const paragraphs = splitTextIntoParagraphs(content);

  return (
    <div>
      {paragraphs.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  );
};
const splitTextIntoParagraphs = (text: string): string[] => {
    return text.split('.').map(paragraph => paragraph.trim()).filter(paragraph => paragraph.length > 0);
  };
export default Article;