import { Button, Card, Modal, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { format } from "date-fns/format";
import { FIREBASE_DB } from "../config/firebaseConfig";
import { collection, doc, onSnapshot } from "firebase/firestore";

// Define the type for a log entry
interface ActivityLog {
    activityId: string;
    userId: string;
    userName: string | null;  // Since user.displayName can be null
    userEmail: string | null; // Since user.email can be null
    userRole: string;
    actionType: string;
    actionDescription: string;
    timestamp: string;
    ipAddress: string;
    userAgent: string;
    resourceId: string;
}

const Logs: React.FC = () => {
    const [showLog, setShowLog] = useState<boolean>(false);
    const [selectedLog, setSelectedLog] = useState<ActivityLog | null>(null);
    const [logs, setLogs] = useState<ActivityLog[]>([]);

    useEffect(() => {
        const activityLogDocId = format(new Date(), 'yyyy-MM-dd');
        const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'activityLogs'), activityLogDocId), (snapshot) => {      
            const data= snapshot.data();
            if(!data) return
            setLogs(data?.activities);
        });
        return () => subscriber();
    }, []);
    const handleLogClick = (log: ActivityLog) => {
        setSelectedLog(log);
        setShowLog(true);
    };

    return (
        <div className="container-lg" style={{minHeight: '80vh'}}>
            <h1>Activity Log</h1>
            <p className="text-muted">Showing Logs for today.</p>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Action</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map((log, index) => (
                        <tr key={index} onClick={() => handleLogClick(log)}>
                            <td>{log.userName}</td>
                            <td>{log.actionType}</td>
                            <td>{log.timestamp}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal show={showLog} backdrop="static" onHide={() => setShowLog(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Activity Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedLog && (
                        <Card>
                            <Card.Body className="gap-2">
                                <Card.Text>
                                    <strong>User:</strong> {selectedLog.userName}
                                </Card.Text>
                                <Card.Text>
                                    <strong>Email:</strong> {selectedLog.userEmail}
                                </Card.Text>
                                <Card.Text>
                                    <strong>Role:</strong> {selectedLog.userRole}
                                </Card.Text>
                                <Card.Text>
                                    <strong>Action:</strong> {selectedLog.actionType}
                                </Card.Text>
                                <Card.Text>
                                    <strong>Description:</strong> {selectedLog.actionDescription}
                                </Card.Text>
                                <Card.Text>
                                    <strong>Time:</strong> {selectedLog.timestamp}
                                </Card.Text>
                                <Card.Text>
                                    <strong>IP Address:</strong> {selectedLog.ipAddress}
                                </Card.Text>
                                <Card.Text>
                                    <strong>User Agent:</strong> {selectedLog.userAgent}
                                </Card.Text>
                                {/* <Card.Text>
                                    <strong>Resource ID:</strong> {selectedLog.resourceId}
                                </Card.Text> */}
                            </Card.Body>
                        </Card>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowLog(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Logs;
