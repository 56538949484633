import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const NotFoundPage: React.FC = () => {
    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <Row>
                <Col className="text-center">
                    <h1 style={{ fontSize: '3rem' }}>404 Page Not Found</h1>
                    <p style={{ fontSize: '1.5rem' }}>The page you are looking for does not exist.</p>
                    
                </Col>
            </Row>
        </Container>
    );
};

export default NotFoundPage;