import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyDQQTWcmFTQ7nLAGyGeGxijmSuiXfvoHgg",

  authDomain: "teksto-2f91c.firebaseapp.com",

  projectId: "teksto-2f91c",

  storageBucket: "teksto-2f91c.appspot.com",

  messagingSenderId: "582233767265",

  appId: "1:582233767265:web:50fa47fae313cc75a21920",

  measurementId: "G-NHZGVVCBK9"

};

// Initialize Firebase
export const FIREBASE_APP = initializeApp(firebaseConfig);
export const FIREBASE_AUTH = getAuth(FIREBASE_APP);
export const FIREBASE_DB = getFirestore(FIREBASE_APP);
export const FIREBASE_STORAGE = getStorage();

