import { Button, Modal } from "react-bootstrap";

interface ModalTermsProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const ModalTerms: React.FC<ModalTermsProps> = ({ show, setShow }) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Terms and Conditions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'auto', padding: '20px' }}>
        <div style={{ paddingBottom: '15px' }}>
          <h5>Welcome to TEKSTO, our little library</h5>
          <p>
            By accessing or using <strong>tekstolib.com</strong>, you agree to comply with and be bound by the following Terms and Conditions:
          </p>
        </div>

        <h6>1. Use of the Site</h6>
        <ul>
          <li>
            <strong>1.1 Educational Purpose Only:</strong> The content provided on the Site is intended only for educational purposes. You agree to use the Site and its content only for personal, non-commercial, and educational purposes.
          </li>
        </ul>

        <h6>2. Restrictions</h6>
        <ul>
          <li>
            <strong>2.1 No Downloading:</strong> You are not allowed to download, copy, or save any content from the Site, including text, unless explicitly authorized by us. All content is intended to be accessed and viewed only.
          </li>
          <li>
            <strong>2.2 No Redistribution:</strong> You may not redistribute, republish, or otherwise make available any content from the Site to others. This includes sharing, copying, or transmitting any content for commercial or non-commercial purposes.
          </li>
        </ul>

        <h6>3. Property</h6>
        <ul>
          <li>
            <strong>3.1 Ownership:</strong> All content on the Site to be used as reference is the property of Rosario Sapangpalay College Inc (SRSPC) and is protected by property laws.
          </li>
          <li>
            <strong>3.2 Usage Rights:</strong> You may access and use the content on the Site only for your personal and educational use. Any other use, including copying, modifying, or distributing content without our express written permission, is prohibited.
          </li>
        </ul>

        <h6>4. Contact Information</h6>
        <p>
          If you have any questions about these Terms, please contact us at: <br />
          <strong>Email:</strong> ittekstocapstone@gmail.com<br />
        </p>

        <h6>5. Acknowledgment</h6>
        <p>
          By using the Site, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. Thank you for visiting Teksto. We appreciate your cooperation and hope you have a positive experience using our Site.
        </p>

        <h6>REPUBLIC ACT NO. 10175</h6>
        <p><strong>AN ACT DEFINING CYBERCRIME, PROVIDING FOR THE PREVENTION, INVESTIGATION, SUPPRESSION AND THE IMPOSITION OF PENALTIES THEREFOR AND FOR OTHER PURPOSES</strong></p>
        <h6>Section 1. Title:</h6>
        <p>
          This Act shall be known as the <strong>"Cybercrime Prevention Act of 2012"</strong>.
        </p>
        <h6>Section 3. Definition of Terms:</h6>
        <p>
          For purposes of this Act, the following terms are hereby defined as follows:
        </p>
        <ul>
          <li>
            <strong>(a) Access:</strong> Refers to the instruction, communication with, storing data in, retrieving data from, or otherwise making use of any resources of a computer system or communication network.
          </li>
          <li>
            <strong>(b) Alteration:</strong> Refers to the modification or change, in form or substance, of an existing computer data or program.
          </li>
          <li>
            <strong>(c) Communication:</strong> Refers to the transmission of information through ICT media, including voice, video, and other forms of data.
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShow(false)}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTerms;
