import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

type ReactPDFViewerProps = { url: string,height: string };
const ReactPDFViewer = ({ url,height }: ReactPDFViewerProps)  => {
    return ( 
        <div className='mt-3' style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: height,
        }}>
            <Viewer fileUrl={url} />
        </div>
     );
}
 
export default ReactPDFViewer;