import { collection, getCountFromServer, getDocs, query, where } from "firebase/firestore";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { FIREBASE_AUTH, FIREBASE_DB } from "../config/firebaseConfig";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";


interface ModalTermsProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const ModalReset: React.FC<ModalTermsProps> = ({ show, setShow }) => {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
  
const resetPassword = async () => {
    if (email.length < 1) {
        toast.error("Please enter your email address");
        return;
    }

    try {
        setIsLoading(true);
        // Check if the email exists in the users collection using count()
        const usersRef = collection(FIREBASE_DB, "users");
        const q = query(usersRef, where("email", "==", email));
        const countSnapshot = await getCountFromServer(q);

        if (countSnapshot.data().count === 0) {
            toast.error("This email address is not registered");
            return;
        }

        // If the email exists, send the password reset email
        await sendPasswordResetEmail(FIREBASE_AUTH, email);
        toast.success("Password reset email sent successfully");
        setShow(false);

    } catch (error) {
        const errorCode = (error as any).code as string;
        if (errorCode === "auth/invalid-email") {
            toast.error("Please enter a valid email address");
        } else if (errorCode === "auth/user-not-found") {
            toast.error("There is no user record corresponding to this identifier. The user may have been deleted");
        } else if (errorCode === "auth/too-many-requests") {
            toast.error("We have blocked all requests from this device due to unusual activity. Try again later");
        } else {
            console.log(error);
            toast.error("An error occurred. Please try again later");
        }
    } finally {
        setIsLoading(false);
    }
};
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Reset Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowY: 'auto', padding: '20px' }}>
        
          <h5>Forgot your password?</h5>
          <p>
            Enter your email address below and we will send you a link to reset your password.
          </p>

        <div className="form-group">
          <input
            type="email"
            className="form-control"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" disabled={isLoading} onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" disabled={isLoading} onClick={resetPassword}>
          Reset Password
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalReset;
