import { collection, doc, onSnapshot, updateDoc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Dropdown, Form, InputGroup, Modal, Pagination, Spinner,Table } from "react-bootstrap";
import { FIREBASE_AUTH, FIREBASE_DB } from "../config/firebaseConfig";
import { FaSearch } from "react-icons/fa";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import ReactPDFViewer from "../components/ReactPDFViewer";
import logActivity from "../scripts/logActivity";
import { User } from "firebase/auth";

const Requests = () => {
    const user= FIREBASE_AUTH.currentUser;
    const [requests, setRequests] = useState<any[] | null>(null);
    const [pages, setPages] = useState<number>(1);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [resultRequests, setResultRequests] = useState<any[]|null>(null);
    const [sort, setSort] = useState('all');
    const [showRequest, setShowRequest] = useState(false);
    const [currentRequest, setCurrentRequest] = useState<any|null>();

    const [filePreview, setFilePreview] = useState('');
    const [isManaged, setIsManaged] = useState(false);
    useEffect(() => {
        const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'requests'),'requests_document'), (snapshot) => {    
            setRequests(snapshot.data()?.requests_items); 
        });
        return () => subscriber();
    }, []);
    useEffect(() => {
        if(!requests) return;
        if(requests.length === 0) {
            setPages(1);
            return;
        };
        const resultRequests = requests.filter((request:any) => {
            return request.studyTitle.toLowerCase().includes(search.toLowerCase()) || request.user.name.toLowerCase().includes(search.toLowerCase()) || request.id.toString().includes(search);
        });
        
        const totalPages =  Math.ceil(resultRequests.length / 10);
        if(currentPage > totalPages) {
            if(totalPages === 0) {
                setCurrentPage(1);
            }
            else {
                setCurrentPage(totalPages);
            }
        };
        setResultRequests(resultRequests.filter((request:any) => request.status === sort || sort === 'all'));
        setPages(totalPages);
    }, [search,requests,sort]);
    useEffect(() => {
        if(!currentRequest) return;
        if(requests?.find((req:any) => req.id === currentRequest.id)?.status === 'pending') {
            setIsManaged(false);
        }else if(requests?.find((req:any) => req.id === currentRequest.id)?.status=== 'approved' || requests?.find((req:any) => req.id === currentRequest.id)?.status === 'rejected') {
            setIsManaged(true);
        }else{
            console.log(currentRequest);
            setIsManaged(false);
        }
    }, [currentRequest,requests]);
    const handleReject = async (request:any) => {
        const updatedRequests = requests?.map((req:any) => {
            if(req.id === request.id) {
                return { ...req, status: 'rejected' };
            }
            return req;
        });
        
        const requestDocRef = doc(FIREBASE_DB, 'requests', 'requests_document');
        await updateDoc(requestDocRef, { requests_items: updatedRequests });
        sendNotification(true,request);
        toast('Request rejected', { icon: '👎' });
        logActivity("Request", user as User, request.study, "Request for full access to the study " + request.studyTitle + " has been rejected by the admin.");
        removeNotification(request.id);
    };
    const handleApprove = async (request:any) => {
        const updatedRequests = requests?.map((req:any) => {
            if(req.id === request.id) {
                return { ...req, status: 'approved' };
            }
            return req;
        });
        
        const requestDocRef = doc(FIREBASE_DB, 'requests', 'requests_document');
        await updateDoc(requestDocRef, { requests_items: updatedRequests });

        const filesDocRef = doc(FIREBASE_DB, 'files', request.study);
        const docSnap = await getDoc(filesDocRef);
        const users = docSnap.data()?.users || [];
        const approvedUser = {
            user_id: request.user.id,
            name: request.user.name,
            dateApproved: new Date().toISOString(),
            expirationDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        }
        await updateDoc(filesDocRef, { users: [...users, approvedUser] });        
      
        sendNotification(false,request);
        toast('Request approved', { icon: '👍' });
        //LOG_THIS_SHIT
        removeNotification(request.id);
    };
    function removeNotification(notification_id:string){
        const docRef = doc(FIREBASE_DB, "admin", "notifications");
        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
                const oldNotifications = docSnap.data().notification_list;
                const newNotifications = oldNotifications.filter((notification:any) => notification.notification_id !== notification_id);
                updateDoc(docRef, { notification_list: newNotifications });
            } else {
                console.log("No such document!");
            }
        });
    }
    function formatDate(dateString: string): string {
        
        const date = new Date(dateString);
      
        const month = date.getUTCMonth() + 1; 
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();
      
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
      
        hours = hours % 12;
        hours = hours ? hours : 12; 
      
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
      
        return `${month}/${day}/${year} - ${hours}:${formattedMinutes} ${ampm}`;
      }
    const sendNotification = async (isRejected:boolean,request:any) => {
       
        const docRef = doc(FIREBASE_DB, "users", request.user.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data().notifications);
            let oldNotifications = docSnap.data().notifications;
            const notification_id = uuidv4();
            console.log(notification_id)
            if(isRejected){
                oldNotifications.push({
                    notification_id: notification_id,
                    status:"rejected",
                    title:"Request Rejected",
                    message:"Your request for full access to the study " + request.studyTitle + "has been rejected.",
                    studyID:request.study,
                    createdAt:new Date().toISOString(),
                    isSeen:false
                });
            }   
            else{
                oldNotifications.push({
                    notification_id: notification_id,
                    status:"approved",
                    title:"Request Approved",
                    message:"Your request for full access to the study " + request.studyTitle + "has been approved.",
                    studyID:request.study,
                    createdAt:new Date().toISOString(),
                    isSeen:false
                });
            }
            await updateDoc(docRef, { notifications: oldNotifications });

        } else {
            console.log("No such document!");
        }
    }
    async function getFile(studyID:string){
        const docRef = doc(FIREBASE_DB, "files", studyID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setFilePreview(docSnap.data().fileLink);
        } else {
            console.log("No such document!");
        }
    }
    return ( 
        <div className="container-lg" style={{minHeight: '80vh'}}>
            <Modal show={showRequest} onHide={()=>{setCurrentRequest(null);setShowRequest(false);setFilePreview('')}} backdrop="static">
                {currentRequest && 
                <>
                <Modal.Header closeButton>
                <Modal.Title>Manage {currentRequest?.user.name}'s Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="">
                    <Card.Body className="d-flex flex-row gap-2">
                    <img
                        src={currentRequest.user.picture?.includes('googleusercontent') ? currentRequest.user.picture.replace(/s\d+-c/, `s${256}-c`) : currentRequest.user.picture || ''}
                        alt="avatar"
                        className="rounded-circle img-fluid"
                        style={{ width: '75px',height:'75px' }}
                    />
                    <div>
                        <h5 className="my-0">{currentRequest.user.name}</h5>               
                        <p className="text-muted my-0">{currentRequest.user.email}</p>
                        <p className="my-0">{currentRequest.user.bio}</p>
                    </div>
   
                    </Card.Body>
                </Card>
                <Card className="my-3">
                    <Card.Header as="h5">Request Details</Card.Header>
                    <Card.Body>
                        
                        <strong>Study Title:</strong> 
                        <p>{currentRequest.studyTitle}</p>
                        
                        <Card.Text>
                        <strong className="mb-2">Reason:</strong>
                        <p className="mb-0">{currentRequest.reason}</p>
                        </Card.Text>
                    </Card.Body>
                </Card>



                
                {filePreview==='' ? 
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> 
                    :
                    <ReactPDFViewer url={filePreview} height='450px'/> 
                }
               
                
                </Modal.Body>
                <Modal.Footer>
                <Button variant="danger" onClick={()=>handleReject(currentRequest)} disabled={isManaged}>
                   Reject
                </Button>
                <Button variant="success" onClick={()=>handleApprove(currentRequest)} disabled={isManaged}>
                    Approve
                </Button>
                </Modal.Footer>
                </>
                }       
            </Modal>
            <h1>Requests</h1>
            <div className='d-flex justify-content-between align-items-center'>
                <InputGroup className="mb-3">
                   
                    <Form.Control
                        type='text'
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={search}
                        onChange={(e)=>setSearch(e.target.value)}
                    />
                     
                     <Dropdown as={ButtonGroup}>
                        <InputGroup.Text id="basic-addon1"> <FaSearch /></InputGroup.Text>

                        <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                        <Dropdown.Menu>  
                            <Dropdown.Item onClick={()=>setSort('all')} active={sort==='all'}>All </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={()=>setSort('pending')} active={sort==='pending'}>Pending </Dropdown.Item>
                            <Dropdown.Item onClick={()=>setSort('approved')} active={sort==='approved'}>Approved</Dropdown.Item>
                            <Dropdown.Item onClick={()=>setSort('rejected')} active={sort==='rejected'}>Rejected</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                     
                </InputGroup>
                
                </div>
            {!requests ? 
                <div className='d-flex justify-content-center align-items-center' style={{height:'75vh'}}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                </div>
                
                :

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            
                            <th>Study Title</th>
                            <th>Reuqested by</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>  
                        {resultRequests
                            ?.slice() // Create a shallow copy of the array
                            .reverse() // Reverse the order of the copy
                            .slice((currentPage - 1) * 10, ((currentPage - 1) * 10) + 10) // Apply pagination to the reversed array
                            .map((request, index) => (
                                <tr
                                    key={request.id}
                                    onClick={() => {
                                        setCurrentRequest(request);
                                        setShowRequest(true);
                                        getFile(request.study);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <td>{request.studyTitle}</td>
                                    <td>{request.user.name}</td>
                                    <td>{formatDate(request.createdAt)}</td>
                                </tr>
                            ))
                        }             
                    </tbody>

                </Table>

                }
                <div className='w-100 d-flex justify-content-center'>
                {pages > 0 && 
                
                <Pagination>
                    
                    <Pagination.Prev disabled={currentPage===1} onClick={()=>setCurrentPage(currentPage-1)}/>
                    {Array.from({length: pages}, (_, i) => i + 1).map((page) => (
                        <Pagination.Item key={page} active={page === currentPage} onClick={() => setCurrentPage(page)}>
                            {page}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next disabled={currentPage===pages} onClick={()=>setCurrentPage(currentPage+1)}/>
                    
                </Pagination>
                }
                
                </div>
        </div>
     );
}
 
export default Requests;