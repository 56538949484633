import axios from 'axios';
import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import FormControl from 'react-bootstrap/esm/FormControl';
import InputGroup from 'react-bootstrap/esm/InputGroup';

import Row from 'react-bootstrap/esm/Row';
import { PiMagnifyingGlassPlusBold } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";

type Search ={
  text:string;
  author:string;
  sort:string;
  earliestPublishDate:string;
  latestPublishDate:string;
  offset:number;
}
interface Props {
  search: Search;
  setSearch: (search: Search) => void;
  handleSubmit: (e: any) => void;
  show:boolean;
  setShow:(show:boolean)=>void;
}
const Hero:React.FC<Props> = ({search,setSearch,handleSubmit,show,setShow})=> {
  
    return ( 
        <Row className="justify-content-center d-flex align-items-center" style={{height:'200px'}}>
          <Col lg={8} md={9} sm={10} xs={11} className="text-center">        
            <Form onSubmit={()=>{}}>
              <Form.Group controlId="searchForm">
                <InputGroup className="mb-3">
 
                    <FormControl
                        type='text'
                        placeholder="Search for a title..."
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                        value={search.text}
                        onChange={(e)=>setSearch({ ...search, text: e.target.value })}
                        />
                    <Button variant="outline-primary" id="button-addon2" type='submit' onClick={handleSubmit}>
                        <FaSearch />
                    </Button>
                </InputGroup>
                
                
                <Button variant="primary" id="button-addon2" onClick={()=>setShow(true)}>
                  <PiMagnifyingGlassPlusBold size={20} className='mb-1'/> Advanced Search 
                </Button>
               
              </Form.Group>
            </Form>
          </Col>
        </Row>
     );
}
 
export default Hero;