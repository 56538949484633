
import Container from 'react-bootstrap/esm/Container';
import Hero from '../components/HomePage/Hero';
import Footer from '../components/Footer';
import FeaturedNews from '../components/HomePage/FeaturedNews';
import FeaturedStudies from '../components/HomePage/FeaturedStudies';
import { useEffect, useState } from 'react';
import { FIREBASE_AUTH, FIREBASE_DB } from '../config/firebaseConfig';
import AdvancedSearch from '../components/HomePage/AdvancedSearch';
import Results from '../components/HomePage/Results';
import { collection, doc, onSnapshot } from 'firebase/firestore';

type Search ={
    text:string;
    author:string;
    sort:string;
    earliestPublishDate:string;
    latestPublishDate:string;
    offset:number;
}
type DateObject = {
    date: string; // Assuming date is in 'YYYY-MM-DD' format
};
interface HomeProps {
    featuredNews: any[];
}
const Home = ({featuredNews}:HomeProps) => {

    const [search, setSearch] = useState<Search>({} as Search);
    const [dataStudies, setDataStudies] = useState<any[]|null>(null);
    const [show, setShow] = useState<boolean>(false);
    const [isLoadingStudies, setIsLoadingStudies] = useState<boolean>(false);
    const [isLoadingNews, setIsLoadingNews] = useState<boolean>(false);
    const [studies, setStudies] = useState<any[]|null>(null);
    const [featuredStudies, setFeaturedStudies] = useState<any[]|null>(null);
    const [dataNews, setDataNews] = useState<any[]|null>(null);
    useEffect(() => {
        const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'studies'), 'studies_document'), (snapshot) => {
            let studies = snapshot.data()?.studies_items.filter((item: { isArchived: boolean; }) => item.isArchived === false);
            setStudies(studies);
            //get the last 4 studies and set it as featured studies
            setFeaturedStudies(studies.slice(-4));     
        });
        return () => subscriber();
    }, []);

    const handleSubmit = async (e: any) => {
      e.preventDefault();
      getStudies();
      getNews(search);
    }

    function getStudies() {
        setIsLoadingStudies(true);
        setDataStudies([]);
    
        let searchResults = studies?.filter((study) => 
            study.title.toLowerCase().includes(search.text.toLowerCase())
        ) as any[];
    
        if (search.author && search.author.length > 0) {
            const searchAuthorLower = search.author.toLowerCase().trim();
            searchResults = searchResults.filter(study => {
                console.log("Filtering study authors:", study.authors); // Log all authors
                return study.authors.some((author: { value: string }) => {
                    const authorLower = author.value.toLowerCase().trim();
                    console.log(`Checking if "${authorLower}" includes "${searchAuthorLower}"`, authorLower.includes(searchAuthorLower));
                    return authorLower.includes(searchAuthorLower);
                });
            });
            console.log("Filtered search results after author filtering:", searchResults);
        }
    
        if (search.earliestPublishDate && search.earliestPublishDate.length > 0) {
            const earliestYear = new Date(search.earliestPublishDate).getFullYear();
            searchResults = searchResults.filter(study => parseInt(study.date) >= earliestYear);
        }
    
        if (search.latestPublishDate && search.latestPublishDate.length > 0) {
            const latestYear = new Date(search.latestPublishDate).getFullYear();
            searchResults = searchResults.filter(study => parseInt(study.date) <= latestYear);
        }
    
        if (search.sort && search.sort.length > 0) {
            searchResults = searchResults.sort((a: { date: string }, b: { date: string }) => {
                const dateA = new Date(a.date).getFullYear();
                const dateB = new Date(b.date).getFullYear();
    
                if (search.sort === 'ASC') {
                    return dateA - dateB;
                } else {
                    return dateB - dateA;
                }
            });
        }
    
        console.log("Final search results:", searchResults);
        setDataStudies(searchResults);
        setIsLoadingStudies(false);
    }
    
    function transformText(input:string) {
        if (input.includes(" ")) {
            return input.trim().replace(/\s+/g, "+");
        } else if (input.includes("-")) {
            return input.trim().replace(/-+/g, "-");
        } else {
            return input;
        }
    }
    function getNews(searchParams: any) {
      setIsLoadingNews(true);
      console.log(searchParams);
     
      if(searchParams.text.length === 0) return setDataNews(null);
      const { text, earliestPublishDate,latestPublishDate,sort,author,offset} = searchParams
      let url = `https://api.worldnewsapi.com/search-news?source-countries=ph`;

      if (text) {
          url += `&text=${transformText(text)}`;
      }
      if (sort) {
          url += `&sort=publish-time&sort-direction=${sort}`;
      }
      if (earliestPublishDate && earliestPublishDate.length > 0) {
          url += `&earliest-publish-date=${earliestPublishDate}`;
      }
      if (latestPublishDate && latestPublishDate.length > 0) {
          url += `&latest-publish-date=${latestPublishDate}`;
      }
      if (author && author.length > 0) {
          url += `&authors=${transformText(author)}`;
      }
      if (offset) {
          url += `&offset=${offset}`;
      }

      //minDate='2022-04-1 00:00:00'
      console.log(url);
      const apiKey = process.env.REACT_APP_NEWS_API;
    
      fetch(url, {
          method: 'GET',
          headers: new Headers({
              'x-api-key': String(apiKey)
          })
      })
      .then(response => {
          if (!response.ok) {
              console.log(response);
          }
          return response.json();
      })
      .then(data => {console.log(data);setDataNews(data);setIsLoadingNews(false);})
      .catch(error =>  console.log(error.message));
    }
    return ( 
      <Container fluid className="align-items-center" style={{minHeight: '80vh'}}>
        
        { dataStudies === null ? 
        <>
        <Hero search={search} setSearch={setSearch} handleSubmit={handleSubmit} show={show} setShow={setShow}/>
        <div className="py-3">
          <h3 className="">Featured Studies</h3>
          {featuredStudies === null ? 
          
          <p>Loading...</p> : 
          
          <FeaturedStudies featuredStudies={featuredStudies}/>}
          
        </div>
        <div className="py-3">
          <h3 className="">Featured News</h3>
          <FeaturedNews featuredNews={featuredNews}/>
        </div>
        
        </>
        :
        
        <Results 
            search={search} 
            setSearch={setSearch} 
            handleSubmit={handleSubmit} 
            show={show} setShow={setShow} 
            isLoadingStudies={isLoadingStudies}
            isLoadingNews={isLoadingNews}
            setIsLoadingNews={setIsLoadingNews}
            dataStudies={dataStudies} 
            dataNews={dataNews}
            getNews={getNews}
            setDataNews={setDataNews}/> 

        }

        <AdvancedSearch 
            getStudies={getStudies}
            search={search}
            setSearch={setSearch}
            show={show} 
            setShow={setShow}
            setIsLoadingNews={setIsLoadingNews}
            setDataNews={setDataNews}
            getNews={getNews}
        />
        {/* <Footer /> */}
      </Container>
     );
}
 
export default Home;