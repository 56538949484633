import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { collection, doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { FIREBASE_AUTH, FIREBASE_DB } from "../config/firebaseConfig";
import { Button, Dropdown, Modal, Spinner, Toast } from "react-bootstrap";
import { MdOutlineLibraryAdd, MdOutlineLibraryAddCheck } from "react-icons/md";
import { LiaUserLockSolid, LiaUserCheckSolid} from "react-icons/lia";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import { RiMailSendFill } from "react-icons/ri";
import Ratings from "../components/Ratings";
import Reviews from "../components/Reviews";
import ReactPDFViewer from "../components/ReactPDFViewer";

type RequestState = 'pending' | 'approved' | 'rejected';

type StudyProps = {
    isAdmin: boolean|null;
};

const Study:React.FC<StudyProps> = ({isAdmin}) => {
    const { id } = useParams();
    const user = FIREBASE_AUTH.currentUser;
    const [study, setStudy] = useState<any>(null);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [requestState, setRequestState] = useState<RequestState|null>(null);
    const [userData, setUserData] = useState<any>(null);
    const [fileLink, setFileLink] = useState<string|null>(null);
    const [showModal, setShowModal] = useState(false);
    const [reason, setReason] = useState('');
    const [hasAccessButExpired, setHasAccessButExpired] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    useEffect(() => {
        const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'studies'), 'studies_document'), (snapshot) => {
            const sd = snapshot.data()?.studies_items.find((study:any) => study.id === id);   
            setStudy(sd);  
        });
        return () => subscriber();
    }, [id]);

    useEffect(() => {
        const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'users'), user?.uid as string), (snapshot) => {      
            const data= snapshot.data(); 
            setUserData(data);  
            data?.savedStudies.includes(id) ? setIsSaved(true) : setIsSaved(false);
        });
        return () => subscriber();
    }, [id, user]);
    useEffect(() => {
        const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'files'), id), (snapshot) => {
            const userAccess = snapshot.data()?.users.find((u: { user_id: string; expirationDate: string }) => u.user_id === user?.uid);
            
            if (userAccess) {
                const expirationDate = new Date(userAccess.expirationDate);
                const currentDate = new Date();
        
                if (expirationDate > currentDate) {
                    setFileLink(snapshot.data()?.fileLink);
                    setRequestState('approved');
                } else {
                   
                    setHasAccessButExpired(true);
                    
                    setRequestState(null);
                }
            } else if (userData?.isAdmin) {
                setFileLink(snapshot.data()?.fileLink);
            } else {
                setRequestState(null);
            }
        });
        return () => subscriber();
    }, [id, user?.uid, userData]);

    const handleSave = async () => {
        if (user) {
            const userRef = doc(collection(FIREBASE_DB, 'users'), user.uid);
            const updatedUserData = { ...userData };
            if (isSaved) {
                const index = updatedUserData.savedStudies.indexOf(id);
                updatedUserData.savedStudies.splice(index, 1);
                toast.error('Study removed from saved studies');
            } else {
                updatedUserData.savedStudies.push(id);
                toast.success('Study saved successfully');
            }
            await setDoc(userRef, updatedUserData);
        }
    }
    const handleRequest = () => {
        console.log(reason)
        if(reason.length === 10){
            toast.error('Reason must be at least 10 characters long')
            return
        }
        if (user) {
            getDoc(doc(collection(FIREBASE_DB, 'requests'), 'requests_document')).then((dc) => {
                const currentRequests = dc.data()?.requests_items
                //check if request already exists
                const req = currentRequests.find((request:any) => request.study === id && request.user.id === user?.uid && request.status === 'pending');
                if (req) {
                    
                    toast.error('Cannot send multiple requests for the same study')
                    return
                }
               
                const newRequestId = uuidv4()
                const newRequest = {
                    id: newRequestId,
                    study: id,
                    studyTitle: study.title,
                    createdAt: new Date().toISOString(),
                    status: 'pending',
                    user:{
                        id: user.uid,
                        name: user.displayName,
                        picture: user.photoURL,
                        email: user.email,
                        bio: userData.bio,
                    },
                    reason: reason,
                    
                }
                currentRequests.push(newRequest)
                setDoc(dc.ref, {requests_items: currentRequests})
                sendNotification(newRequestId);
                toast.success('Request sent successfully')
               
            });
            
        }
        setReason('');
        handleClose();
    }
    const sendNotification = async (newRequestId:string) => {
       
        const docRef = doc(FIREBASE_DB, "admin", 'notifications');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
           
            let oldNotifications = docSnap.data().notification_list;
            
            oldNotifications.push({
                notification_id: newRequestId,
                status:"Request",
                title:"Study Access Request from " + user?.displayName,
                message:user?.displayName+" has requested access to " + study.title,
                studyID:study.id,
                createdAt:new Date().toISOString(),
                isSeen:false
            });
            await updateDoc(docRef, { notification_list: oldNotifications });

        } else {
            console.log("No such document!");
        }
    }       
    
    function requestButton() {
        if (requestState === null) {
            return <Button variant="primary" onClick={handleShow}><LiaUserLockSolid className="mb-1"/> Request Full Access</Button>
        } else if (requestState === 'pending') {
            return <Button variant="primary" onClick={handleRequest} disabled><RiMailSendFill className="mb-1"/> Request Sent!</Button>
        } else if (requestState === 'approved'){
            return <Button variant="primary" disabled><LiaUserCheckSolid className="mb-1"/> Already Fully Accessed</Button>
        }else if (requestState === 'rejected'){
            return <Button variant="primary" onClick={handleRequest}><LiaUserLockSolid className="mb-1"/> Request Full Access</Button>
        }
    }
    function citeAPA() {
        let title = study.title;
        let authors = study.authors.map((author: any) => author.value);
        let date = study.date;
        let source = "Unpublished manuscript, Sto. Rosario Sapang Palay College Inc.";
    
        // Format the date
        function getYearFromDateString(dateString: string) {
            // Split the string by space and get the second element, which is the year
            const parts = dateString.split(' ');
            return parts.length > 1 ? parts[1] : dateString;
        }
    
        // Format the authors with 'et al.' if there are 3 or more authors
        let formattedAuthors = '';
        if (authors.length > 2) {
            const [firstAuthor] = authors[0].split(' ');
            formattedAuthors = `${firstAuthor} ${authors[0].charAt(0)}., et al.`;
        } else {
            formattedAuthors = authors.map((author: { split: (arg0: string) => [any, any]; }) => {
                const [lastName, firstName] = author.split(' ');
                return `${lastName} ${firstName.charAt(0)}.`;
            }).join(', ');
        }
    
        // Construct the APA citation
        const citation = `${formattedAuthors} (${getYearFromDateString(date)}). ${title}. ${source}`;
        navigator.clipboard.writeText(citation).then(() => {
            toast.success('APA Citation copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy citation:', err);
        });
    }
    
    function citeMLA() {
        let title = study.title;
        let authors = study.authors.map((author: any) => author.value);
        let date = study.date;
        let source = "Unpublished manuscript, Sto. Rosario Sapang Palay College Inc.";
      
        // Format the authors according to MLA rules
        let formattedAuthors;
        const authorCount = authors.length;
      
        if (authorCount === 1) {
          // One author
          const [lastName, firstName] = authors[0].split(', ');
          formattedAuthors = `${lastName}, ${firstName}`;
        } else if (authorCount === 2) {
          // Two authors
          const [lastName1, firstName1] = authors[0].split(', ');
          const [lastName2, firstName2] = authors[1].split(', ');
          formattedAuthors = `${lastName1}, ${firstName1}, and ${firstName2} ${lastName2}`;
        } else {
          // Three or more authors
          const [lastName, firstName] = authors[0].split(', ');
          formattedAuthors = `${lastName}, ${firstName}, et al.`;
        }
      
        // Construct the MLA citation
        const citation = `${formattedAuthors}. "${title}." ${source}, ${date}`;
      
        navigator.clipboard.writeText(citation).then(() => {
          toast.success('MLA Citation copied to clipboard');
        }).catch(err => {
          console.error('Failed to copy citation:', err);
        });
      }
 
    return ( 
        <Container fluid style={{minHeight: '80vh'}}>
            <Modal
                size="lg"
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title>Request Full Access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    To request full access to this {study?.title}, please indicate the reason for your request.
                    <textarea className="form-control mt-2" placeholder="Reason for request" 
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    rows={3} style={{ resize: 'none' }}/>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleRequest}>Request Full Access</Button>
                </Modal.Footer>
            </Modal>
             {study === null ? 
                <Col lg={12} md={12} sm={12} xs={12} className="text-center d-flex justify-content-center align-self-center"style={{minHeight:'80vh'}}>
                    <Spinner variant="primary" animation="border" role="status" className='align-self-center'>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Col>:
                    <>
                        {study.isArchived ? 
                        <Row className="d-flex justify-content-center">
                            <Col lg={6} md={8} sm={10} xs={12} className="text-center">
                                <h1 className="text-danger mt-5">Study is Archived</h1>
                                <p className="text-muted">This study has been archived and is no longer available for public access</p>
                            </Col>
                        </Row> : 
                        <>
                        <Row className="d-flex justify-content-center">
                    
                            <Col lg={6} md={8} sm={10} xs={12}>
                                <p className="fs-1 fw-bold">{study.title}</p>

                                <span className="text-muted">Authors</span>
                                <p className="fs-5 text-muted fw-bold">{study.authors.map((author:any) => author.value).join(', ')}</p>
                                <p className="fs-6 text-muted">{study.date}</p>
                                <div className="d-flex justify-content-between">
                                    <h5 className="align-self-bottom mt-2">Abstract</h5>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic" size="sm">
                                            Cite
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item as={Button} onClick={()=>citeAPA()}>APA Citation</Dropdown.Item>
                                            <Dropdown.Item as={Button} onClick={()=>citeMLA()}>MLA Citation</Dropdown.Item>
                                            
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                
                                <p className="fs-6">{study.abstract}</p>
                                <div className="d-flex gap-2">
                                {!isAdmin && <>
                                    {isSaved ? <Button variant="secondary" onClick={handleSave}><MdOutlineLibraryAddCheck className="mb-1"/> Unsave</Button> : <Button variant="primary"onClick={handleSave}><MdOutlineLibraryAdd className="mb-1"/> Save</Button>}
                                    {requestButton()}
                                </> 
                                }
                                    
                                
                                </div>
                            </Col>
                            
                        </Row>
                        <Row className="d-flex justify-content-center">
                            <Col lg={6} md={8} sm={10} xs={12} className="">
                            {fileLink && <ReactPDFViewer url={fileLink} height="900px"/>}
                            </Col>
                        </Row>

                        <Ratings study={study} isAdmin={isAdmin} fileLink={fileLink}/>
                        <Reviews study={study} isAdmin={isAdmin} fileLink={fileLink}/>
                        </>
                        }
                    </>
            } 
            {!study?.isArchived &&
            <Toast 
                onClose={() => setHasAccessButExpired(false)} 
                show={hasAccessButExpired} 
                delay={3000} 
                autohide 
                style={{
                    position: 'fixed',  // Keeps it fixed in place during scroll
                    bottom: '20px', 
                    right: '20px', 
                    zIndex: 1050,  // Ensures it floats above other elements
                    maxWidth: '90%',  // Makes sure it won't exceed 90% of the screen width
                    width: 'auto'     // Automatically adjusts width based on content
                }}
            >
                <Toast.Header>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/teksto-2f91c.appspot.com/o/assets%2Flogo.png?alt=media&token=3c8705ee-46b2-4978-9abe-890845e3f6dd"
                        className="rounded me-2"
                        alt=""
                        style={{width: '20px'}}
                    />
                    <strong className="me-auto">Teksto</strong>
                </Toast.Header>
                <Toast.Body>Your access for this study has expired, please file a request again to regain access.</Toast.Body>
            </Toast>
            }
           
        </Container>
    );
}

export default Study;
