import { collection, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton, Form, FormControl, InputGroup, ListGroup, Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaSearch } from 'react-icons/fa';
import { FIREBASE_AUTH, FIREBASE_DB } from '../config/firebaseConfig';
import SavedStudies from '../components/Library/SavedStudies';
import SavedNews from '../components/Library/SavedNews';
import formatDate from '../scripts/getDate';
import { useNavigate } from "react-router-dom";

const Library = () => {
    const navigate = useNavigate();
    const user = FIREBASE_AUTH.currentUser;
    const [search, setSearch] = useState<string>('');
    const [savedStudies, setSavedStudies] = useState<any>([]);
    const [savedNews, setSavedNews] = useState<any>([]);
    const [show, setShow] = useState<boolean>(false);
    const [requests, setRequests] = useState<any>([]);
    const handleSubmit = (e:any) => {
        e.preventDefault();
        console.log(search);
    }
    useEffect(() => {
        const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'users'), user?.uid as string), (snapshot) => {      
            const data= snapshot.data();
            setSavedNews(data?.savedNews);
            let savedStudies_ids = data?.savedStudies; 
            if(savedStudies_ids){
                getSavedStudies(savedStudies_ids);
            }
           
        });
        return () => subscriber();
    }, [user?.uid]);

    useEffect(() => {
        const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'requests'),'requests_document'), (snapshot) => {   
            let requests = snapshot.data()?.requests_items; 
            requests = requests.filter((item: any) => item.user.id === user?.uid && item.status === "pending");
            setRequests(requests);
        });
        return () => subscriber();
    }, [user?.uid]);


    async function getSavedStudies(ids: string[]) {
        const docRef = doc(FIREBASE_DB, "studies", "studies_document");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let studies = docSnap.data().studies_items.filter((item: any) => 
                ids.includes(item.id) && item.isArchived !== true
              );
            setSavedStudies(studies);
        } else {
            console.log("No such document!");
        }
    }

    return ( 
        <div className='container-md' style={{minHeight: '80vh'}}>
            <div className='flex-row d-flex justify-content-between w-100 align-items-center'>
                <h2>My Library</h2>
                <div>
                    <Button variant="primary" size="sm" onClick={()=>setShow(true)}>Pending Requests</Button>
                </div>
               
            </div>
            <Modal show={show} onHide={()=>setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Current Pending Access Requests</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {requests.length === 0 ? <p className='text-muted'>No requests</p>
                    : 
                    <ListGroup variant="flush">
                        {requests.map((request:any) => (
                            <ListGroup.Item action onClick={()=>navigate('/study/'+request.study)} key={request.id}>
                                <h6 className='mb-0'>{request.studyTitle}</h6>
                                <p className='text-muted'>{formatDate(request.createdAt)}</p>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    
                    }         
                </Modal.Body>
                
            </Modal>
            {/* <Form onSubmit={()=>{}}>
              <Form.Group controlId="searchForm">
                <InputGroup className="mb-3">
                    <FormControl
                        type='text'
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={search}
                        onChange={(e)=>setSearch(e.target.value)}
                        />
                    <Button variant="outline-primary" id="button-addon2" type='submit' onClick={handleSubmit} >
                        <FaSearch />
                    </Button>
                    <DropdownButton id="dropdown-basic-button" title="Sort by">
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </DropdownButton>
                </InputGroup>
              </Form.Group>
            </Form> */}
            <Tabs
                defaultActiveKey="studies"
                id="fill-tab-example"
                className="mb-3 custom-tab" 
                fill
            >
               
                <Tab eventKey="studies" title="Studies">
                    <SavedStudies studies={savedStudies} />
                </Tab>
                <Tab eventKey="article" title="Articles">
                    <SavedNews news={savedNews} />
                </Tab>
            </Tabs>
        </div>
        
     );
}
 
export default Library;