import { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Button, Spinner } from "react-bootstrap";
import formatDate from "../../scripts/getDate";
import { useNavigate } from "react-router-dom";
interface FeaturedNewsProps {
    featuredNews: any[];
}
const FeaturedNews = ({featuredNews}:FeaturedNewsProps) => {
  
    const navigate = useNavigate();
    
    return (
        <Row className="justify-content-center d-flex align-items-center">
            {featuredNews.length === 0 ? 
            <Spinner animation="border" role="status" variant="success">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            :
            <>
                {featuredNews.map((news, i) => (
                    <Col lg={3} md={4} sm={6} xs={12} className="d-flex justify-content-between h-auto" key={i}>
                        <Card>
                            <Card.Header className="text-muted text-sm small">{formatDate(news.publish_date)}</Card.Header>
                            <Card.Img variant="bottom" src={news.image} style={{ width:'100%', objectFit: 'cover',height:'300px' }}/>
                            <Card.Body>
                                <div style={{height:'100px',overflow: 'hidden'}}>
                                <Card.Title>{news.title}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{news.authors.join(', ')}</Card.Subtitle>
                                </div>
                               
                                <Card.Text style={{height:'150px',overflow: 'hidden'}}>
                                    {news.text}
                                </Card.Text>
                                <div className="d-flex justify-content-between align-items-end">
                                    <Button variant="success" onClick={() => navigate('/news/' + news.id)}>
                                        View News
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </>
            }       
        </Row>
    );
};

export default FeaturedNews;
