import { useState } from "react";
import ModalTerms from "./ModalTerms";

const Footer = () => {
    const [show, setShow] = useState(false);
    return ( 
    
      <footer className="py-3 my-4 text-center">
        © 2024 Copyright: Teksto | <span className="link-opacity-100 text-primary" style={{cursor:'pointer'}} onClick={()=>setShow(true)}>Terms and Conditions</span>
        <ModalTerms show={show} setShow={setShow} />
      </footer>
      
    );
}
 
export default Footer;