import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import About from './pages/About';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBar from './components/Header/NavigationBar';
import Auth from './pages/Auth';
import { FIREBASE_AUTH, FIREBASE_DB } from './config/firebaseConfig';
import { User, onAuthStateChanged } from 'firebase/auth';
import NotFoundPage from './pages/NotFoundPage';
import Library from './pages/Library';
import Settings from './pages/Settings';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import Dashboard from './pages/Dashboard';
import Study from './pages/Study';
import { arrayUnion, collection, doc, getDoc, setDoc, updateDoc, } from "firebase/firestore";

import Requests from './pages/Requests';
import Resources from './pages/Resources';
import News from './pages/News';
import { Worker } from '@react-pdf-viewer/core';
import { format } from 'date-fns';
import LandingPage from './pages/Landing';
import Moderation from './pages/Moderation';
import Logs from './pages/Logs';
import AllUsers from './pages/AllUsers';
import Footer from './components/Footer';

function App() {
  const [user,setUser] = useState<User | null | number>(0);
  const [isAdmin, setIsAdmin] = useState<boolean|null>(null);
  const [isSuperDuperAdmin, setIsSuperDuperAdmin] = useState<boolean>(false);
  const [featuredNews, setFeaturedNews] = useState<any[]>([]);
  const isFetchedRef = useRef(false);
  useEffect(() => {
    async function checkCustomClaims() {
      try {
          const currentUser = FIREBASE_AUTH.currentUser;
          if (!currentUser) {
              return;
          }
    
          // Get the ID token result, which contains custom claims
          const idTokenResult = await currentUser.getIdTokenResult();
    
          if(!!idTokenResult.claims.superAdmin){
            console.log('Super Admin');
            setIsAdmin(true);
            setIsSuperDuperAdmin(true);
          }else if(!!idTokenResult.claims.admin){
            console.log('Admin');
            setIsAdmin(true);
            setIsSuperDuperAdmin(false);
          }else{
            console.log('Not Admin');
            setIsAdmin(false);
            setIsSuperDuperAdmin(false);
            logVisit(currentUser.uid);
            getFeaturedNews();
          }
         
      } catch (error) {
          console.error('Error checking custom claims:', error);
      }
    }
    const unsubscribe = onAuthStateChanged(FIREBASE_AUTH, async (user) => {
        if (user) {
            await user.reload();  // Reload the user's data

            if (user.emailVerified === false) {
                console.log('Email not verified');
                FIREBASE_AUTH.signOut();
                return;
            }
            setUser(user);

            const userDoc = await getDoc(doc(collection(FIREBASE_DB, 'users'), user.uid));
            setIsAdmin(userDoc.data()?.isAdmin);

            await checkCustomClaims();

        } else {
            setUser(null);  // Clear the user state if not signed in
        }
    });

    return () => unsubscribe();  // Cleanup on unmount
  }, []);

  function getFeaturedNews() {
      if (isFetchedRef.current) {
          return;
      }
      console.log("Fetching Featured News...");
      const fetchNews = async () => {
          const url = 'https://api.worldnewsapi.com/search-news?source-countries=ph&min-sentiment=-0.1&max-sentiment=0.1&number=4&sort=publish-time&sort-direction=DESC';
          const apiKey = process.env.REACT_APP_NEWS_API;

          try {
              const response = await fetch(url, {
                  method: 'GET',
                  headers: new Headers({
                      'x-api-key': String(apiKey)
                  })
              });

              if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
              }

              const data = await response.json();
              setFeaturedNews(data.news);
              isFetchedRef.current = true; // Mark as fetched
          } catch (error) {
              console.log(error);
          }
      };

      fetchNews();
  }
  async function logVisit(userId: string) {
    console.log('Logging visit...');
      const today = format(new Date(), 'yyyy-MM-dd'); // Format date as 'YYYY-MM-DD'
      const visitDocRef = doc(FIREBASE_DB, 'visits', today);  
      try {
        await updateDoc(visitDocRef, {
          
          users: arrayUnion(userId),
        });
      } catch (error) {
        //@ts-ignore
        if (error.code === 'not-found') {  
          await setDoc(visitDocRef, {
            date: today,   
            users: [userId],
          });
        } else {
          console.error('Error logging visit:', error);
        }
      }
  }
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
    <BrowserRouter>
      {user===0  ? 
      <LoadingView /> :
        <>
          {!user ? 
          <Routes>
              <Route path="/">
              <Route index element={<LandingPage />}/>
              
              <Route path="*" element={<Auth />} />
              </Route>
          </Routes>
          :
            <>   
            {isAdmin===null ? <LoadingView /> :
            <>
              <NavigationBar isAdmin={isAdmin} isSuperAdmin={isSuperDuperAdmin}/>
                <Routes>
              
                <Route path="/">
                  <Route index element={isAdmin ? <Dashboard/> : <Home featuredNews={featuredNews}/>} />
                  
                  <Route path="settings" element={<Settings isAdmin={isAdmin} isSuperDuperAdmin={isSuperDuperAdmin}/>} />
                  <Route path="about" element={<About />} />
                  <Route path="study/:id" element={<Study isAdmin={isAdmin}/>} />
                  <Route path="news/:id" element={<News />} />
                  <Route path="*" element={<NotFoundPage />} />
                  
                  {!isAdmin && <Route path="library" element={<Library />} />}
              
                  {isAdmin && <Route path="requests" element={<Requests/>}/>}
                  {isAdmin && <Route path="resources" element={<Resources/>}/>}

                  {isSuperDuperAdmin && <Route path="adminrequests" element={<Moderation/>}/>}
                  {isSuperDuperAdmin && <Route path="allusers" element={<AllUsers />} />}
                  {isSuperDuperAdmin && <Route path="logs" element={<Logs />} />}
          
                  </Route>
                </Routes>
                <Footer/>
              </>
            }     
          </>
          }
        </>
      }
      <Toaster />
    </BrowserRouter>
    </Worker>
  );
}

export const LoadingView = () => {
  return (
    <Container fluid className="d-flex vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col className="text-center">
          <Spinner animation="border" variant="primary" />
        </Col>
      </Row>
    </Container>
  );
}
export default App;
