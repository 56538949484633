import { Row,Col,Card,Button, Badge } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
interface Props {
    featuredStudies:any[];
}
const FeaturedStudies:React.FC<Props> = ({featuredStudies}) => {
    const navigate = useNavigate();
    return ( 
        <Row className="justify-content-center d-flex align-items-center">
            {featuredStudies.map((study:any,i:number)=>(
                <Col lg={3} md={4} sm={6} xs={12} className="d-flex justify-content-between" key={i}>
                    <Card className="">
                        <Card.Header className="text-muted text-sm small">{study.date}</Card.Header>
                        
                        <Card.Body>
                            <div style={{height:'100px',overflow: 'hidden'}}>
                                <Card.Title><Badge bg="secondary">New</Badge> {study.title} </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    {study.authors.map((author:any) => author.value).join(', ')}
                                </Card.Subtitle>
                            </div>
                            
                            <Card.Text className="text-truncate text-wrap" style={{height:'150px',overflow: 'hidden'}}>
                                {study.abstract}
                            </Card.Text>
                            <div className="d-flex justify-content-between align-items-end">
                                <Button variant="primary" onClick={()=>navigate('/study/'+study.id)}>View Study</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
                     
        </Row>
    );
}
 
export default FeaturedStudies;