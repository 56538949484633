import { useState } from "react";
import { Row,Col, Card,Image, Form, Button, ListGroup } from "react-bootstrap";
import toast from "react-hot-toast";
import { FIREBASE_AUTH, FIREBASE_DB } from "../config/firebaseConfig";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import logActivity from "../scripts/logActivity";
import { User } from "firebase/auth";
const Reviews =  ({study,isAdmin,fileLink}: {study: any,isAdmin:boolean|null,fileLink:string|null}) => {
    const user = FIREBASE_AUTH.currentUser;
    const [comment, setComment] = useState('');
    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (comment.length < 2) {
            toast.error("Please enter more than 2 characters");
            return;
        }
        const newReview = {
            id: user?.uid,
            name: user?.displayName,
            image: user?.photoURL,
            comment: comment,
            date: new Date().toLocaleDateString()
        }
        const updatedStudy = {
            ...study,
            reviews: [...study.reviews, newReview]
        };
        getDoc(doc(collection(FIREBASE_DB, 'studies'), 'studies_document')).then((dc) => {
            const currentStudies = dc.data()?.studies_items
            const updatedStudies = currentStudies.map((s: any) => s.id === study.id ? updatedStudy : s)
            setDoc(dc.ref, {studies_items: updatedStudies})
            setComment('');
            toast.success("Feedback submitted successfully");
        });
        logActivity("Review", user as User, study.id, "User added a review to " + study.title);
        sendNotification(study);
    }
    const sendNotification = async (study:any) => {
       
        const docRef = doc(FIREBASE_DB, "admin", 'notifications');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
           
            let oldNotifications = docSnap.data().notification_list;
            const notification_id = uuidv4();
            
            oldNotifications.push({
                notification_id: notification_id,
                status:"Review",
                title:"New review on " + study.title,
                message:user?.displayName+" added a review to " + study.title+".",
                studyID:study.id,
                createdAt:new Date().toISOString(),
                isSeen:false
            });
            await updateDoc(docRef, { notification_list: oldNotifications });

        } else {
            console.log("No such document!");
        }
    }       
    return ( 
        <Row className="d-flex justify-content-center mt-2">    
            <Col lg={6} md={8} sm={10} xs={12} className="justify-content-center align-content-center mb-5">   
            {!isAdmin && fileLink &&
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3 d-flex flex-column" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Write your feedback:</Form.Label>
                    <Form.Control as="textarea" rows={3} style={{ resize: "none" }} value={comment} onChange={(e) => setComment(e.target.value)}/>
                    <Button variant="primary" type="submit" className="mt-2 align-self-end ml-auto">Submit</Button>
                </Form.Group>
            </Form>
            }
            
            {study.reviews.length > 0 ? <h5>Reviews</h5> : <h5 className="text-muted">No reviews yet</h5>}
            
            <ListGroup variant="flush">
                {study.reviews.slice().reverse().map((review: any) => (
                    <ListGroup.Item key={review.id}>
                        <Row className="align-items-center">
                            <Col xs="auto" className="align-self-start">
                                <Image src={review.image} roundedCircle width="60" height="60" alt="User Image" />
                            </Col>
                            <Col>
                                <Card.Title>{review.name}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{review.date}</Card.Subtitle>
                                <Card.Text>{review.comment}</Card.Text>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                ))}
                
            </ListGroup>            
            </Col>
        </Row>
     );
}
 
export default Reviews;