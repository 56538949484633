import { useEffect, useState } from "react";
import { Button, Card, Col, Pagination } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import formatDate from "../../scripts/getDate";
const ResultStudies = ({data}: any) => {
    const navigate = useNavigate();
    const [pages, setPages] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        if (data && data.length > 0) {
            setPages(Math.ceil(data.length / 10)); // Calculate the number of pages
            setCurrentPage(1); // Reset to the first page when new data is loaded
        }
    }, [data]);
    
    return ( 
        <Col lg={8} style={{minHeight:'80vh'}} className="d-flex flex-column justify-content-between">
            <div className="w-100">
            {data && data.length > 0 ? data.slice((currentPage - 1) * 10,((currentPage - 1) * 10) + 10).map((item:any,i:number)=>{
                return <Card className="mb-2" key={i} border="primary">
                            <Card.Header className="text-muted text-sm small">{formatDate(item.date)}</Card.Header>
                            
                            <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    {item.authors.map((author:any) => author.value).join(', ')}
                                </Card.Subtitle>
                                <Card.Text className="text-truncate text-wrap" style={{overflow: 'hidden'}}>
                                    {item.abstract}
                                </Card.Text>
                                <div className="d-flex justify-content-between align-items-end">
                                    <Button variant="primary" onClick={()=>navigate('/study/'+item.id)}>View Study</Button>
                                </div>
                            </Card.Body>
                        </Card>
            }) : 
            <Col lg={8} md={9} sm={10} xs={11} className="text-center m-auto text-muted h-100 w-100" >
                <h5 className="w-100 mt-5">No results found</h5>
            </Col>
            }
            </div>
            <div className='w-100 d-flex justify-content-center'>
                {pages > 0 && 
                
                <Pagination>
                    
                    <Pagination.Prev disabled={currentPage===1} onClick={()=>setCurrentPage(currentPage-1)}/>
                    {Array.from({length: pages}, (_, i) => i + 1).map((page) => (
                        <Pagination.Item key={page} active={page === currentPage} onClick={() => setCurrentPage(page)}>
                            {page}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next disabled={currentPage===pages} onClick={()=>setCurrentPage(currentPage+1)}/>
                    
                </Pagination>
                }
                
                
            </div>
        </Col>
    );
}
 
export default ResultStudies;