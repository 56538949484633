
import React, { useState,useRef } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import Modal from 'react-bootstrap/esm/Modal';
import { Overlay } from 'react-bootstrap';
import toast from 'react-hot-toast';
type Search ={
    text:string;
    author:string;
    sort:string;
    earliestPublishDate:string;
    latestPublishDate:string;
    offset:number;
}
interface AdvancedSearchProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoadingNews: React.Dispatch<React.SetStateAction<boolean>>;
    setDataNews: React.Dispatch<React.SetStateAction<any[] | null>>;
    getNews: (searchParams: any) => void;
    search: Search;
    setSearch: (search: Search) => void;
    getStudies: () => void;
}

const AdvancedSearch: React.FC<AdvancedSearchProps> = ({ show,setShow,getNews,search,setSearch,getStudies}) => {
   
    const handleSubmit = async (e: any) => {
      
        e.preventDefault();
        if(!search.text || search.text === "" || search.text.length >= 100) {
            
            toast.error('Search text is empty or too long');
        }else{
          getNews(search);
          getStudies();
          setShow(false)
        }
        
    }
    

    return (
        <Modal show={show} onHide={() => setShow(false)} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Advanced Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Search Text</Form.Label>
              <Form.Control
                type="text"
                placeholder="Student mental health"
                autoFocus
                value={search.text}
                onChange={(e) => setSearch({ ...search, text: e.target.value })}
               />
              <Form.Label>Author</Form.Label>
              <Form.Control
                type="text"
                placeholder="Robert Greene"     
                value={search.author}
                onChange={(e) => setSearch({ ...search, author: e.target.value })} 
              />
            </Form.Group>
            
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              
              <Form.Label>Choose Date</Form.Label>
              <div className="d-flex justify-content-between gap-3 align-items-center mb-2">
                <Form.Control type="date" 
                value={search.earliestPublishDate}
                onChange={(e) => setSearch({ ...search, earliestPublishDate: e.target.value })}
                />
                <Form.Label className='m-0'>To</Form.Label>
                <Form.Control type="date"
                value={search.latestPublishDate}
                onChange={(e) => setSearch({ ...search, latestPublishDate: e.target.value })}
                />
              </div>
              <Form.Label>Sort by</Form.Label>
              <Form.Select aria-label="Default select example"
              value={search.sort}
              onChange={(e) => setSearch({ ...search, sort: e.target.value })}
              >
                <option value="DESC">Newest</option>
                <option value="ASC">Oldest</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            //@ts-ignore
              setSearch({text:'',author:'',earliestPublishDate:'',latestPublishDate:''});
              setShow(false);
          }}>
          Clear
          </Button>
          
          <Button variant="primary" onClick={handleSubmit} type='submit' >
            Search
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default AdvancedSearch;