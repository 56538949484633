import { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Dropdown, Form, InputGroup, Modal, Pagination, Spinner,Badge } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { MdOutlineLibraryAdd } from "react-icons/md";
import AuthorSelect from '../components/AuthorSelect';
import { deleteObject, getDownloadURL, getMetadata, ref, uploadBytesResumable } from "firebase/storage";
import toast from 'react-hot-toast';
import { arrayUnion, collection, doc, getDoc, onSnapshot, runTransaction, setDoc } from 'firebase/firestore';
import { FIREBASE_AUTH, FIREBASE_DB,FIREBASE_STORAGE } from '../config/firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import { FaSearch } from 'react-icons/fa';
import formatDate from '../scripts/getDate';
import logActivity from '../scripts/logActivity';
import { User } from 'firebase/auth';

interface Option {
    readonly label: string;
    readonly value: string;
}


const Resources = () => {
    const user = FIREBASE_AUTH.currentUser;
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [editing, setEditing] = useState<Boolean>(false);
    const [showEdit, setShowEdit] = useState(false);
    const [studies, setStudies] = useState<any[]|null>(null);
    const [show, setShow] = useState(false);

    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [abstract, setAbstract] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<readonly Option[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentStudy, setCurrentStudy] = useState<any|null>(null);
    const [search, setSearch] = useState('');
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [resultStudies, setResultStudies] = useState<any[]|null>(null);
    const [isAscending, setIsAscending] = useState(true);
    const [approvedUsers, setApprovedUsers] = useState<any[]>([]);
    useEffect(() => {
        const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'studies'), 'studies_document'), (snapshot) => {
            let studies = snapshot.data()?.studies_items;
            //@ts-ignore
            setStudies(studies.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()));     
        });
        return () => subscriber();
    }, []);

    useEffect(() => {
        if(!studies) return;
        if(studies.length === 0) {
            setPages(1);
            return;
        };
        let resultStudies = studies.filter((study) => 
            study.title.toLowerCase().includes(search.toLowerCase()) ||
            study.authors.some((author: Option) => author.label.toLowerCase().includes(search.toLowerCase())) ||
            study.date.includes(search.toLowerCase()) ||
            study.abstract.toLowerCase().includes(search.toLowerCase())
        );

        //let filteredStudies = resultStudies.filter(item => item.isArchived === false);
        let filteredStudies = resultStudies;
        console.log(filteredStudies);
        
        const totalPages =  Math.ceil(filteredStudies.length / 10);
        if(currentPage > totalPages) {
            if(totalPages === 0) {
                setCurrentPage(1);
            }
            else {
                setCurrentPage(totalPages);
            }
        };
        setResultStudies(!isAscending ? filteredStudies : filteredStudies.reverse());
        setPages(totalPages);
    }, [search,studies,isAscending]);
    async function addStudy() {
        setLoading(true);
        //@ts-ignore
        const file = fileInputRef.current!.files[0];
        if (!file) {
            toast.error('Please select a file to upload');
            setLoading(false);
            return;
        }   
        const fileLink = await uploadFile(file);
        const _id = uuidv4();
        const study = {
            id: _id, 
            title: title,
            date: date,
            abstract: abstract,
            ratings: [],
            reviews: [],
            authors: value,
            isArchived: false,
        }

        setDoc(doc(FIREBASE_DB, "studies", "studies_document"),{studies_items: arrayUnion(...[study]),},{ merge: true });
        await setDoc(doc(FIREBASE_DB, "files", _id), {fileLink: fileLink,users:[]});
        toast.success('Study uploaded successfully!');
        logActivity("Study", user as User, _id, "Admin uploaded the study " + title);
        setLoading(false);
        handleClose();
    }
    
    async function uploadFile(file: File): Promise<string> {
        
        const storageRef = ref(FIREBASE_STORAGE, 'studies/' + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        return new Promise<string>((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    toast.error('Error uploading the study! Please try again.');
                    console.log(error);
                    reject(error);
                },
                () => {
                    
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL);
                    });
                }
            );
        });
    }

    const handleClose = () => {
        setTitle('')
        setDate('')
        setAbstract('')
        setInputValue('')
        setValue([])
        fileInputRef.current!.value = '';
        setShow(false)
        setCurrentStudy(null)
        setShowEdit(false)
    };
    const handleOpen = () => {
        setShow(true);
    } 

    const viewStudy = async (study:any) => {
        const docRef = doc(FIREBASE_DB, "files", study.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const file = docSnap.data().fileLink;
            const sd = {...study, file};
            setApprovedUsers(docSnap.data().users);
            setCurrentStudy(sd); 
            setShowEdit(true);
            setTitle(sd.title);
            setDate(sd.date);
            setAbstract(sd.abstract);
            setValue(sd.authors);
           
        } else {
        console.log("No such document!");
        }

    }
    function setForm(){
        setTitle(currentStudy.title);
        setDate(currentStudy.date);
        setAbstract(currentStudy.abstract);
        setValue(currentStudy.authors);
        fileInputRef.current!.value = '';
    }
    async function updateStudy() {
        setLoading(true);
      
        try {
          //@ts-ignore
          const file = fileInputRef.current!.files[0];
          const study = {
            id: currentStudy.id,
            title: title,
            date: date,
            abstract: abstract,
            authors: value,
            ratings: currentStudy.ratings,
            reviews: currentStudy.reviews,
            isArchived: currentStudy.isArchived,
          };
      
          await runTransaction(FIREBASE_DB, async (transaction) => {
            const studiesDocRef = doc(FIREBASE_DB, "studies", "studies_document");
            const filesDocRef = doc(FIREBASE_DB, "files", study.id);
      
            const studiesDoc = await transaction.get(studiesDocRef);
            const filesDoc = await transaction.get(filesDocRef);
      
            let currentStudies = studiesDoc.data()?.studies_items || [];
            const index = currentStudies.findIndex((s: { id: any; }) => s.id === currentStudy.id);
      
            if (index !== -1) {
              currentStudies[index] = study;
            }
      
            transaction.set(studiesDocRef, { studies_items: currentStudies }, { merge: true });
      
            if (file) {
                const oldFile = filesDoc.data()?.fileLink

                if (oldFile) {
                    const oldFileRef = ref(FIREBASE_STORAGE, oldFile);
                    
                    try {
                        // Check if the file exists by getting its metadata
                        const metadata = await getMetadata(oldFileRef);
                        console.log('File exists:', metadata);
                    
                        // If it exists, proceed to delete
                        await deleteObject(oldFileRef);
                        console.log('File deleted successfully');
                    } catch (error:any) {
                        if (error.code === 'storage/object-not-found') {
                        // Handle file not found error
                        console.log('File does not exist');
                        } else {
                        // Handle other possible errors
                        console.error('Error checking or deleting the file:', error);
                        }
                    }
                }
        
                const fileLink = await uploadFile(file);
                const studyFile = { fileLink: fileLink, users:filesDoc.data()?.users};
        
                transaction.set(filesDocRef, studyFile);

                fileInputRef.current!.value = '';
            }
          });
      
          
        } catch (error) {
          console.error("Transaction failed: ", error);
          toast.error('Error updating the study! Please try again.');
        } finally {
            toast.success('Study updated successfully!');
          setEditing(false);
          setLoading(false);
        }
    }
    async function archiveStudy() {
        setLoading(true);
        try {
            await runTransaction(FIREBASE_DB, async (transaction) => {
                const studiesDocRef = doc(FIREBASE_DB, "studies", "studies_document");
                const studiesDoc = await transaction.get(studiesDocRef);
                let currentStudies = studiesDoc.data()?.studies_items || [];
                const index = currentStudies.findIndex((s: { id: any; }) => s.id === currentStudy.id);
                if (index !== -1) {
                    currentStudies[index].isArchived = true;
                }
                transaction.set(studiesDocRef, { studies_items: currentStudies }, { merge: true });
                toast.success('Study archived successfully!');

                //LOG_THIS_SHIT
                setLoading(false);
                handleClose();
            });
        } catch (error) {
            console.error("Transaction failed: ", error);
            toast.error('Error archiving the study! Please try again.');
        }
    }
    async function unarchiveStudy() {
        setLoading(true);
        try {
            await runTransaction(FIREBASE_DB, async (transaction) => {
                const studiesDocRef = doc(FIREBASE_DB, "studies", "studies_document");
                const studiesDoc = await transaction.get(studiesDocRef);
                let currentStudies = studiesDoc.data()?.studies_items || [];
                const index = currentStudies.findIndex((s: { id: any; }) => s.id === currentStudy.id);
                if (index !== -1) {
                    currentStudies[index].isArchived = false;
                }
                transaction.set(studiesDocRef, { studies_items: currentStudies }, { merge: true });
                
            });
        } catch (error) {
            console.error("Transaction failed: ", error);
            toast.error('Error unarchiving the study! Please try again.');
        } finally {
            toast.success('Study unarchived successfully!');
            setLoading(false);
            handleClose();
        }
    }
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type !== "application/pdf") {
            alert("Only PDF files are allowed!");
            if (fileInputRef.current) {
                fileInputRef.current.value = ""; // Clear the input
            }
        }
    };
    return ( 
        <div style={{minHeight: '80vh'}}>
            <Modal show={showEdit} onHide={handleClose} size="lg" backdrop="static">
                <Modal.Header>
                <Modal.Title>Manage Study</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} disabled={!editing}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Authors</Form.Label>
                        <AuthorSelect 
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            value={value}
                            setValue={setValue}   
                            disabled={!editing}       
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="text" value={date} onChange={(e) => setDate(e.target.value)} disabled={!editing}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Abstract</Form.Label>
                        <Form.Control as="textarea" rows={7} value={abstract} onChange={(e) => setAbstract(e.target.value)} disabled={!editing}/>
                    </Form.Group>
                </Form>
               
                
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Update PDF File</Form.Label>
                    <Form.Control type="file" ref={fileInputRef} disabled={!editing} accept=".pdf" onChange={handleFileChange} />
                </Form.Group>
                <h6>Approved Users</h6>
                <Table bordered striped>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Date Accessed</th>
                            <th>Expiration Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {approvedUsers
                            .filter(user => new Date(user.expirationDate) > new Date())
                            .map((user, index) => (
                                <tr key={index}>
                                    <td>
                                        
                                        {user.name}
                                    </td>
                                    <td>{formatDate(user.dateApproved)}</td>
                                    <td>{formatDate(user.expirationDate)}</td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
                </Modal.Body>
                <Modal.Footer>
                
                <Button variant="secondary" onClick={()=>{
                    if(editing){
                        setEditing(false);
                        setForm();
                    }else{
                        handleClose();
                    }
                    
                }}>
                    {editing ? 'Cancel' : 'Close'}
                </Button>
                <Button variant="danger" onClick={currentStudy && currentStudy.isArchived ? unarchiveStudy: archiveStudy} disabled={loading}>{currentStudy && currentStudy.isArchived ? 'Unarchive Study' : 'Archive Study'}</Button>
                <Button variant="primary" onClick={()=>{
                    if(!editing){
                        setEditing(true);
                    }else{
                        updateStudy()
                        
                    }   
                }} disabled={loading}>
                    {editing ? 'Update' : 'Edit'}
                </Button>
                </Modal.Footer>
            </Modal>
            <div className='container-lg'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                    <h1>Resources</h1>
                    <p className='text-muted'>Total Studies: {studies?.length}</p>
                    </div>
                    
                    <div>
                        <Button variant='primary' onClick={handleOpen}><MdOutlineLibraryAdd className='mb-1'/> Add Study</Button>
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                <InputGroup className="mb-3">
                   
                    <Form.Control
                        type='text'
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={search}
                        onChange={(e)=>setSearch(e.target.value)}
                    />
                     
                     <Dropdown as={ButtonGroup}>
                        <InputGroup.Text id="basic-addon1"> <FaSearch /></InputGroup.Text>

                        <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />

                        <Dropdown.Menu>
                        <Dropdown.Header>Order:</Dropdown.Header>
                            <Dropdown.Item onClick={()=>setIsAscending(true)} active={isAscending}>Newest First</Dropdown.Item>
                            <Dropdown.Item onClick={()=>setIsAscending(false)} active={!isAscending}>Oldest First</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                     
                </InputGroup>
                
                </div>
                {!studies ? 
                <div className='d-flex justify-content-center align-items-center' style={{height:'75vh'}}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                </div>
                
                :

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            
                            <th>Title</th>
                            <th>Authors</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>  
                        {resultStudies?.slice((currentPage - 1) * 10,((currentPage - 1) * 10) + 10).map((study, index) => (
                            <tr key={study.id} onClick={() => viewStudy(study)} style={{ cursor: 'pointer' }}>
                                
                                <td>{study.isArchived && <Badge bg="secondary">Archived</Badge>} {study.title}</td>
                                <td>
                                    {study.authors.map((author: Option, index: number) => (
                                        <span key={index}>{author.label}{index === study.authors.length - 1 ? '' : ', '}</span>
                                    ))}
                                </td>
                                <td>{study.date}</td>
                            </tr>
                        ))}             
                    </tbody>
                </Table>

                }
                <div className='w-100 d-flex justify-content-center'>
                {pages > 0 && 
                
                <Pagination>
                    
                    <Pagination.Prev disabled={currentPage===1} onClick={()=>setCurrentPage(currentPage-1)}/>
                    {Array.from({length: pages}, (_, i) => i + 1).map((page) => (
                        <Pagination.Item key={page} active={page === currentPage} onClick={() => setCurrentPage(page)}>
                            {page}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next disabled={currentPage===pages} onClick={()=>setCurrentPage(currentPage+1)}/>
                    
                </Pagination>
                
                }
                
                </div>
               
            </div>
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static">
                <Modal.Header closeButton>
                <Modal.Title>Add Study</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
                        
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Authors</Form.Label>
                        <AuthorSelect 
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            value={value}
                            setValue={setValue}           
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="text" value={date} onChange={(e) => setDate(e.target.value)}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Abstract</Form.Label>
                        <Form.Control as="textarea" rows={7} value={abstract} onChange={(e) => setAbstract(e.target.value)}/>
                    </Form.Group>
                </Form>
               
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Attach PDF file</Form.Label>
                    <Form.Control type="file" ref={fileInputRef} accept=".pdf"  onChange={handleFileChange} />
                </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={addStudy} disabled={loading}>
                    Upload
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
     );
}
 
export default Resources;