import { useEffect, useState } from "react";
import ReactPDFViewer from "../ReactPDFViewer";

interface SplitFileViewProps {
    studies: any[];
    studyLinks: any[];
}
const SplitFileView: React.FC<SplitFileViewProps> = ({studies,studyLinks}) => {
    const links = studies.map(study => {
        const link = studyLinks.find(link => link.study_id === study.id);
        return link ? link.fileLink : null;
    });

    useEffect(() => {
        console.log(links);
    }, [links]);
    return ( 
        <div className="w-100 d-flex gap-1">
        <div className="w-50 flex-column align-items-center">
            <div className="d-flex align-items-center justify-content-center" style={{ height: '100px', textAlign: 'center' }}>
                <h3>{studies[0].title}</h3>
            </div>
            <ReactPDFViewer url={links[0]} height="800px" />
        </div>
    
        {/* Divider */}
        <div style={{ width: '1px', backgroundColor: '#ccc', height: '900px', alignSelf: 'center' }}></div>   
            <div className="w-50 flex-column align-items-center">
                <div className="d-flex align-items-center justify-content-center" style={{ height: '100px', textAlign: 'center' }}>
                    <h3>{studies[1].title}</h3>
                </div>
                <ReactPDFViewer url={links[1]} height="800px" />
            </div>
        </div>
    


     );
}
 
export default SplitFileView;