import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { CgDarkMode } from "react-icons/cg";
const ThemeToggleButton = () => {
  // State to manage the current theme
  const [theme, setTheme] = useState('light');

  // Effect to update the data-bs-theme attribute on the <html> element
  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', theme);
  }, [theme]);

  // Function to toggle the theme
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    
      <CgDarkMode onClick={toggleTheme} size={26} className='me-2' cursor={'pointer'}/>
   
  );
};

export default ThemeToggleButton;
