import { User } from "firebase/auth";
import { v4 as uuid } from "uuid";
import { doc, getDoc, setDoc, arrayUnion } from "firebase/firestore";
import { FIREBASE_DB } from "../config/firebaseConfig";
import { format } from 'date-fns';
 // Change this to your actual document ID

export default async function logActivity(action: string, user: User, resourceId: string, actionDescription?: string) {
    try {
        // Get user role based on custom claims
        const userRole = await checkCustomClaims(user);

        // Construct the activity log
        const activity = {
            activityId: uuid(),
            userId: user.uid,
            userName: user.displayName,
            userEmail: user.email,
            userRole: userRole,
            actionType: action,
            actionDescription: actionDescription || "No description provided.",
            timestamp: new Date().toISOString(), // Use current time
            ipAddress: "192.168.1.10", // Replace with actual IP retrieval logic if needed
            userAgent: navigator.userAgent, // Get actual user agent
            resourceId: resourceId,
        };
        const activityLogDocId = format(new Date(), 'yyyy-MM-dd');
        // Reference to the activity logs document
        const activityLogDocRef = doc(FIREBASE_DB, 'activityLogs', activityLogDocId);

        // Get the existing document (if it exists)
        const activityLogDoc = await getDoc(activityLogDocRef);

        if (activityLogDoc.exists()) {
            // If the document exists, update the array of activity logs
            await setDoc(activityLogDocRef, {
                activities: arrayUnion(activity) // Use arrayUnion to add to the existing array
            }, { merge: true });
        } else {
            // If the document does not exist, create it with the activities array
            await setDoc(activityLogDocRef, {
                activities: [activity] // Initialize with the first activity
            });
        }

        console.log('Activity logged:', activity);
    } catch (error) {
        console.error('Error logging activity:', error);
    }
}

async function checkCustomClaims(user: User) {
    try {
        const idTokenResult = await user.getIdTokenResult();
        
        if (idTokenResult.claims.superAdmin) {
            return 'superAdmin';
        } else if (idTokenResult.claims.admin) {
            return 'admin';
        } else {
            return 'user';
        }
    } catch (error) {
        console.error('Error checking custom claims:', error);
        return 'unknown'; // Return a default value in case of error
    }
}
