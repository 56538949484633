
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MdMenu } from "react-icons/md";
import { FIREBASE_AUTH, FIREBASE_DB } from '../../config/firebaseConfig';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { IoLibraryOutline, IoLogOutOutline, IoNotificationsCircle, IoNotificationsOutline, IoSettingsOutline } from "react-icons/io5";
import Notifications from './Notifications';
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import ThemeToggleButton from './ThemeToggleButton';
import {  LuLayoutDashboard } from "react-icons/lu";
import { GrResources } from "react-icons/gr";
import { TbDeviceIpadMinus, TbHomeSearch, TbLogs } from "react-icons/tb";
import { Button, Card } from 'react-bootstrap';
import { FaEdit, FaUsers, FaUserShield } from 'react-icons/fa';
import { collection, doc, onSnapshot } from 'firebase/firestore';

type NavigationBarProps = {
    isAdmin: boolean|null;
    isSuperAdmin?: boolean;
};
  function NavigationBar({ isAdmin,isSuperAdmin }: NavigationBarProps) {
    const user = FIREBASE_AUTH.currentUser;
   
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [hasNotifications, setHasNotifications] = useState(false);
    useEffect(() => {
        if(!user) return

        if(isAdmin) {
            const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'admin'), 'notifications'), (snapshot) => {
                const data = snapshot.data();
                setHasNotifications(data?.notification_list?.some((n:any) => !n.isSeen) || false);
            });
            return () => subscriber();
        }else{
            const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'users'), user?.uid as string), (snapshot) => {      
                const data= snapshot.data();          
                if(!isAdmin){
                    setHasNotifications(data?.notifications?.some((n:any) => !n.isSeen) || false);
                }
            });
            return () => subscriber();
        }
      }, [isAdmin, user]);
    return ( 
        <Navbar className="bg-body-tertiary">
            <Container fluid className='d-flex justify-content-between'>
                <Navbar.Brand onClick={() => navigate('/')} style={{cursor:'pointer'}}>
                    <img
                    alt=""
                    src="https://firebasestorage.googleapis.com/v0/b/teksto-2f91c.appspot.com/o/assets%2Fbanner.png?alt=media&token=93f1f9ae-c27a-4a27-8723-57f90d8d7923https://firebasestorage.googleapis.com/v0/b/teksto-2f91c.appspot.com/o/assets%2Fbanner.png?alt=media&token=31c0ffdc-e079-42b1-a4b6-d785a2cd3a0f"
                   
                    height="30"
                    className="d-inline-block align-top"
                    />{' '}
                   
                </Navbar.Brand>
                <div className="position-relative ">
                    <ThemeToggleButton />

                    <div className="position-relative d-inline-block">
                        <IoNotificationsCircle 
                        onClick={handleShowModal} 
                        size={28} 
                        
                        cursor="pointer" 
                        />
                        {hasNotifications &&
                        <span 
                        className="position-absolute top-0 start-100 bg-danger border border-light rounded-circle"
                        style={{
                            width: '10px',
                            height: '10px',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1, // Ensures the badge stays on top
                        }}
                        />}
                    </div>

                    <MdMenu onClick={handleShow} size={36} cursor="pointer" className='ms-2' />
                </div>


                

                    <Offcanvas show={show} onHide={handleClose} placement='end'>
                        <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Teksto</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                        <ListGroup variant="flush">
                       
                        
                            
                       
                            <ListGroup.Item action className="d-flex align-items-start" onClick={() => {navigate('/settings');handleClose()}}>
                                <Image src={user?.photoURL ? user?.photoURL : 'https://firebasestorage.googleapis.com/v0/b/teksto-2f91c.appspot.com/o/assets%2F1.png?alt=media&token=f00a1313-ffe9-4f6b-81d9-803e99dd94f8'} roundedCircle style={{width: "48px",height:"48px"}} alt="Avatar" /> 
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{user?.displayName}</div>
                                    {user?.email}
                                </div>         
                            </ListGroup.Item>
                            
                            <ListGroup.Item action onClick={() => {navigate('/');handleClose()} }>
                                {isAdmin ? <LuLayoutDashboard size={24} className='me-2'/> : <TbHomeSearch size={24} className='me-2'/>}
                                {isAdmin ? 'Dashboard' : 'Home'}
                            </ListGroup.Item>
                            
                            {isAdmin &&
                                <ListGroup.Item action onClick={() => {navigate('/resources');handleClose()} }>
                                    <GrResources size={24} className='me-2'/> 
                                    Resources
                                </ListGroup.Item>
                            }
                            {isAdmin &&
                                <ListGroup.Item action onClick={() => {navigate('/requests');handleClose()} }>
                                    <HiOutlineClipboardDocumentList size={24} className='me-2'/> 
                                    Requests
                                </ListGroup.Item>
                            }
                            {!isAdmin &&
                             <ListGroup.Item action onClick={() => {navigate('/library');handleClose()} }>
                                <IoLibraryOutline size={24} className='me-2'/> 
                                 Library
                            </ListGroup.Item>
                            }
                            {isSuperAdmin &&
                                <ListGroup.Item action onClick={() => {navigate('/adminrequests');handleClose()} }>
                                    <TbDeviceIpadMinus size={24} className='me-2'/> 
                                    Admin Requests
                                </ListGroup.Item>
                            }
                            {isSuperAdmin &&
                                <ListGroup.Item action onClick={() => {navigate('/allusers');handleClose()} }>
                                    <FaUsers size={24} className='me-2'/> 
                                    All Users
                                </ListGroup.Item>
                            }
                             {isSuperAdmin &&
                                <ListGroup.Item action onClick={() => {navigate('/logs');handleClose()} }>
                                    <TbLogs size={24} className='me-2'/> 
                                    Logs
                                </ListGroup.Item>
                            }
                            <ListGroup.Item action onClick={() => {navigate('/settings');handleClose()} }>
                                <IoSettingsOutline size={24} className='me-2'/> 
                                Settings
                            </ListGroup.Item>
                       
                            <ListGroup.Item action onClick={()=>FIREBASE_AUTH.signOut()}>
                                <IoLogOutOutline size={24} className='me-2'/>
                                Sign-out
                            </ListGroup.Item>
                        </ListGroup>
                        </Offcanvas.Body>
                    </Offcanvas>
            </Container>
            <Notifications isAdmin={isAdmin} showModal={showModal} handleClose={handleCloseModal} handleShow={handleShowModal} setShow={setShow}/>
        </Navbar>
     );
}
 
export default NavigationBar;