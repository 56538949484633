import { KeyboardEventHandler } from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};



const createOption = (label: string) => ({
  label,
  value: label,
});

interface AuthorSelectProps{
    inputValue:string;
    setInputValue:any;
    value:readonly {label:string,value:string}[];
    setValue:any;
    disabled?:boolean;
}

const AuthorSelect:React.FC<AuthorSelectProps> = ({inputValue,setInputValue,value,setValue,disabled}) => {
    
    const handleKeyDown: KeyboardEventHandler = (event) => {
      if (!inputValue) return;
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          setValue((prev: any) => [...prev, createOption(inputValue)]);
          setInputValue('');
          event.preventDefault();
      }
    };

  return (
    <CreatableSelect
    styles={{
        control: (base, state) => ({
          ...base,
          borderColor: state.isFocused ? '#80bdff' : '#ced4da',
          '&:hover': { borderColor: '#80bdff' },
          boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 'none',
          borderRadius: '.25rem',
          minHeight: 'calc(1.5em + .75rem + 2px)',
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: '#e9ecef',
          borderRadius: '.25rem',
          padding: '0 .25rem',
        }),
        multiValueLabel: (base) => ({
          ...base,
          color: '#495057',
        }),
        multiValueRemove: (base) => ({
          ...base,
          color: '#495057',
          ':hover': {
            backgroundColor: '#dc3545',
            color: 'white',
          },
        }),
        input: (base) => ({
          ...base,
          minHeight: 'calc(1.5em + .75rem + 2px)',
        }),
        placeholder: (base) => ({
          ...base,
          color: '#6c757d',
        }),
      }}
      isDisabled={disabled}
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={(newValue) => setValue(newValue)}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder="Type something and press enter..."
      value={value}
    />
  );
};

export default AuthorSelect;