import {  Button, Card, Dropdown, Form, InputGroup, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import formatDate from "../../scripts/getDate";
import { LuImport } from "react-icons/lu";
import { useEffect, useState } from "react";
import { ImLink } from "react-icons/im";
import toast from "react-hot-toast";
import { doc, updateDoc } from "firebase/firestore";
import { FIREBASE_AUTH, FIREBASE_DB } from "../../config/firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import Article from "../Article";
interface SavedNewsProps {
    news: any[];        
}

const SavedNews: React.FC<SavedNewsProps> = ({news}) => {
    const user = FIREBASE_AUTH.currentUser;
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [link, setLink] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    
    const [extractedNews, setExtractedNews] = useState<any>(null);
    const [newsPreview, setNewsPreview] = useState<any>(null);
    const [showNewsPreview, setShowNewsPreview] = useState<boolean>(false);

    const handleClose = () => {setShow(false);setExtractedNews(null);setLink('');};
    const handleShow = () => setShow(true);
    function importNews(){
        console.log(link);
        const url = 'https://api.worldnewsapi.com/extract-news?url='+link;
        const apiKey = process.env.REACT_APP_NEWS_API;

        const fetchNews = () => {
            setLoading(true); // Set loading to true before starting the fetch call
            return fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'x-api-key': String(apiKey)
                })
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                if (data.title === "Error, malformed request. Make sure your URL is typed correctly. If you believe you are receiving this message in error,") {
                    throw new Error('Error! Make sure the link is correct.');
                } else if (data.title === "502 Bad Gateway") {
                    throw new Error('Error! We cannot access the article at the moment.');
                } else if (data.title === "This site cannot be reached") {
                    throw new Error('Error! This site cannot be reached');
                } else if (data.title === "") {
                    throw new Error('Error! No article found');
                } else {
                    return data;
                }
            });
        };
    
        toast.promise(
            fetchNews().then(data => {
                setExtractedNews(data);
                setLoading(false); // Set loading to false after the fetch is successful
            }).catch(error => {
                setLoading(false); // Set loading to false if an error occurs
                throw error;
            }),
            {
                loading: 'Importing article...',
                success: 'Article imported successfully!',
                error: (err) => err.message,
            }
        );
    
    }

    useEffect(() => {
        if(newsPreview!=null){
            setShowNewsPreview(true);
        }else{
            setShowNewsPreview(false);
        }
    },[newsPreview]);

    async function saveNews(){
        let updatedNews = [...news];
        let newNews = {...extractedNews,id:uuidv4(),isImported:true}; 
        updatedNews.push(newNews);      
        const newsRef = doc(FIREBASE_DB, 'users', user?.uid as string);
        await updateDoc(newsRef, { savedNews: updatedNews });
        setShow(false);
    }
    async function unsave(){
        let updatedNews = [...news];
        updatedNews = updatedNews.filter((n)=>n.id!==newsPreview.id);
        const newsRef = doc(FIREBASE_DB, 'users', user?.uid as string);
        await updateDoc(newsRef, { savedNews: updatedNews });
        setShowNewsPreview(false);
        setNewsPreview(null);
    }
    function citeAPA(){
        const formatAuthors = (authors: string[]): string => {
            if(!authors) return '';
            return authors.map(author => {
                const [firstName, ...lastName] = author.split(' ');
                return `${lastName.join(' ')}, ${firstName.charAt(0)}.`;
            }).join(', ');
        };
        
        const formatDate = (date: string): string => {
            const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString('en-US', options);
        };
        
        const { title, url, publish_date, authors } = newsPreview;
        
        const apaCitation = `${formatAuthors(authors)} (${formatDate(publish_date)}). ${title}. Tribune. ${url}`;

        navigator.clipboard.writeText(apaCitation).then(() => {
            toast.success('APA Citation copied to clipboard');
          }).catch(err => {
            console.error('Failed to copy citation:', err);
          });
        
    }
    function citeMLA(){
        const formatAuthorsMLA = (authors: string[]): string => {
            if(!authors) return '';
            return authors.map(author => {
                const [firstName, ...lastName] = author.split(' ');
                return `${lastName.join(' ')}, ${firstName}.`;
            }).join(', ');
        };
        
        const formatDateMLA = (date: string): string => {
            const [year, month, day] = date.split(' ')[0].split('-');
            const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
            return `${parseInt(day, 10)} ${months[parseInt(month, 10) - 1]} ${year}`;
        };
        
        const { title, url, publish_date, authors } = newsPreview;
        
        const mlaCitation = `${formatAuthorsMLA(authors)}. "${title}." Tribune, ${formatDateMLA(publish_date)}, ${url}.`;
        navigator.clipboard.writeText(mlaCitation).then(() => {
            toast.success('APA Citation copied to clipboard');
          }).catch(err => {
            console.error('Failed to copy citation:', err);
          });
    }
    return ( 
        <div>
            {newsPreview && 
            <Modal
            show={showNewsPreview}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={()=>{setShowNewsPreview(false);setNewsPreview(null)}}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" >
                    {newsPreview.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   
                        <Card>
                            <Card.Header>{formatDate(newsPreview.publish_date)}</Card.Header>
                            {newsPreview.image && <Card.Img variant="top" src={newsPreview.image} />}
                            
                            <Card.Body>
                                <Card.Title>{newsPreview.title}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{newsPreview.authors ? newsPreview.authors.join(', '):''}</Card.Subtitle>
                                <Card.Text><Article content={newsPreview.text} /></Card.Text>
                            </Card.Body>
                        </Card>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={unsave}>Unsave</Button>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Cite
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item as={Button} onClick={()=>citeAPA()}>APA Citation</Dropdown.Item>
                            <Dropdown.Item as={Button} onClick={()=>citeMLA()}>MLA Citation</Dropdown.Item>
                            
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant="success"  onClick={()=>window.open(newsPreview.url, '_blank')}>View Original Article</Button>
                </Modal.Footer>
            </Modal>
            }
            
            <div className="d-flex justify-content-end">
                <Button variant="success" size="sm" onClick={handleShow} className="mb-2"><LuImport style={{marginBottom:2}}/> Import Article</Button>
            </div>
            
            {news.map((n:any) => (
                <Card className="mb-2" key={n.id} border="success">
                    <Card.Header>{formatDate(n.publish_date)}</Card.Header>
                    <Card.Body>
                        <Card.Title>{n.title}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{n.authors ? n.authors.join(', '):''}</Card.Subtitle>
                        <Card.Text style={{height:'100px',overflow:'hidden'}}>
                        {n.text}
                        </Card.Text>
                        <Button variant="success" onClick={()=>{
                            if(n.isImported){
                                console.log(n);
                                setNewsPreview(n);
                                
                            }else{
                                navigate('/news/'+n.id);
                            }
                        }}>View Article</Button>      
                    </Card.Body>
                </Card>
            ))}
           <Modal
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" >
                    Import Article
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Paste the link of an article:</h6>
                    <Form onSubmit={(e)=>{e.preventDefault()}}>
                        <Form.Group controlId="searchForm">
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><ImLink /></InputGroup.Text>
                            <Form.Control
                            type="text"
                            value={link}
                            onChange={(e)=>setLink(e.target.value)}
                            className="custom-input"
                            placeholder="Paste article link here"
                            aria-label="Paste article link here"
                            aria-describedby="basic-addon1"
                            />
                            <Button variant="success" type='submit' disabled={loading} onClick={()=>importNews()}>Extract</Button>
                        </InputGroup>
                        </Form.Group>
                    </Form>
                    {extractedNews && (
                        <Card>
                            <Card.Header>{formatDate(extractedNews.publish_date)}</Card.Header>
                            {extractedNews.image && <Card.Img variant="top" src={extractedNews.image} />}
                            
                            <Card.Body>
                                <Card.Title>{extractedNews.title}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{extractedNews.authors ? extractedNews.authors.join(', ') : ''}</Card.Subtitle>
                                <Card.Text><Article content={extractedNews.text} /></Card.Text>
                            </Card.Body>
                        </Card>
                        )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="success" onClick={saveNews} disabled={extractedNews===null}>Save</Button>
                </Modal.Footer>
            </Modal>
           
        </div>
     );
}
 
export default SavedNews;