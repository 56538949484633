
import { Container, Row, Col, Form, Button, Image, Modal, Toast } from 'react-bootstrap';
import { useState } from 'react';
import { FIREBASE_AUTH, FIREBASE_DB } from '../config/firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile,sendPasswordResetEmail, sendEmailVerification } from 'firebase/auth';
import { signInWithPopup, GoogleAuthProvider,  } from "firebase/auth";
import toast from 'react-hot-toast';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { FaGoogle } from 'react-icons/fa';
import ModalTerms from '../components/ModalTerms';
import ModalReset from '../components/ModalReset';

interface Props {
    isRegister?: boolean;
}
const Auth:React.FC<Props> = ({isRegister}) => {
    
    const auth = FIREBASE_AUTH;
    const [loading, setLoading] = useState<boolean>(false);
    const [isSignUp, setIsSignUp] = useState<boolean>(isRegister ? true : false);
    
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [show, setShow] = useState(false);
    const [toastShow, setToastShow] = useState(false);
    const [showResend, setShowResend] = useState(false);
    const [isStudent, setIsStudent] = useState(true);

    const [firstName, setFirstName] = useState<string>(''); // First name field
    const [middleName, setMiddleName] = useState<string>(''); // Middle name (optional) field
    const [lastName, setLastName] = useState<string>(''); // Last name field

    const [showReset, setShowReset] = useState(false);

    const signIn = async () => {
        setLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password);
            if(auth.currentUser?.emailVerified===false){
                toast.error("Please verify your email address first");
                setShowResend(true);
                setLoading(false);
                return;
            }
            writeUserData(auth.currentUser?.uid as string,auth.currentUser?.email as string);
            toast.success("Signed in successfully");
            
        } catch (error) {
            const errorCode = (error as any).code as string;
            if(errorCode==="auth/invalid-email"){
                toast.error("Please enter a valid email address");
            }
            else if(errorCode==="auth/user-not-found"){
                toast.error("There is no user record corresponding to this identifier. The user may have been deleted");
            }
            else if(errorCode==="auth/wrong-password"){
                toast.error("The password is invalid or the user does not have a password");
            }
            else if(errorCode==="auth/too-many-requests"){
                toast.error("We have blocked all requests from this device due to unusual activity. Try again later");
            }
            
        }
        setLoading(false);
    }
    const signUp = async () => {
        
        setLoading(true);
        if (!firstName || !lastName) {
            toast.error("Please enter your first and last name");
            setLoading(false);
            return;
        }

        const name = `${firstName} ${middleName ? middleName + ' ' : ''}${lastName}`;

        if(password.length<7){
            toast.error("Please enter a valid password");
            setLoading(false);
            return;
        }
        if(password!==confirmPassword){
            toast.error("Please make sure the passwords match");
            setLoading(false);
            return;
        }
        if (checkPasswordStrength(password)===false) {
            setLoading(false);
            return;
        }
        try {
            await createUserWithEmailAndPassword(auth, email, password)   
            if (auth.currentUser) {
                
                updateProfile(auth.currentUser, {
                    displayName: name,
                    photoURL: "https://firebasestorage.googleapis.com/v0/b/teksto-2f91c.appspot.com/o/assets%2F1.png?alt=media&token=f00a1313-ffe9-4f6b-81d9-803e99dd94f8"
                });  
    
                sendEmailVerification(auth.currentUser)
                .then(() => {
                    setIsSignUp(false);
                    toast.success("To continue, please verify your email address. A verification email has been sent to your inbox");
                })
            }
        } catch (error) {
            const errorCode = (error as any).code as string;
            if(errorCode==="auth/invalid-email"){
                toast.error("Please enter a valid email address");
            }else if(errorCode==="auth/weak-password"){
                toast.error("The password must be 6 characters long or more");
            }else if(errorCode==="auth/email-already-in-use"){
                toast.error("The email address is already in use by another account");
            }else if(errorCode==="auth/operation-not-allowed"){
                toast.error("Password sign-in is disabled for this project");
            }else if(errorCode==="auth/too-many-requests"){
                toast.error("We have blocked all requests from this device due to unusual activity. Try again later");
            }else{
                console.log(error);
                toast.error("An error occurred. Please try again later");
            }

            
        }
        setLoading(false);
    }

    
    async function writeUserData(userId:string,email:string){
        const userRef = doc(FIREBASE_DB, 'users', userId);
        const userDocSnapshot = await getDoc(userRef);           
        if(userDocSnapshot.exists()===false){
            await setDoc(userRef, {
                bio: "",
                email: email,
                savedNews: [],
                savedStudies: [],
                notifications: [],
                requests: [],
                isStudent: isStudent,   
            });
        }
    }
    const checkPasswordStrength = (password: string) => {
        const hasUpperCase = /[A-Z]/.test(password);  // Check for uppercase letter
        const hasLowerCase = /[a-z]/.test(password);  // Check for lowercase letter
        const hasNumber = /\d/.test(password);        // Check for a number
    
        if (!hasUpperCase) {
            toast.error("Password must contain at least one uppercase letter.");
            return false;
        } else if (!hasLowerCase) {
            toast.error("Password must contain at least one lowercase letter.");
            return false;
        } else if (!hasNumber) {
            toast.error("Password must contain at least one number.");
            return false;
        } else {
            return true;
        }
    }
    const resendVerificationEmail = async () => {
        setLoading(true);
        await signInWithEmailAndPassword(auth, email, password).then(async () => {
            await sendEmailVerification(auth.currentUser as any);
            toast.success("Verification email sent successfully");
        }).catch((error) => {
            console.log(error);
            toast.error("An error occurred. Please try again later");
        });
        setLoading(false);
    }
    return ( 
        <Container>
            <Toast onClose={() => setToastShow(false)} show={toastShow} delay={3000} autohide>
                <Toast.Header>
                    <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                    />
                    <strong className="me-auto">Bootstrap</strong>
                    <small>11 mins ago</small>
                </Toast.Header>
                <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
            </Toast>
            <section className="vh-100">
                <Container fluid className="h-custom">
                    <Row className="d-flex justify-content-center align-items-center h-100 mb-5">
                    <Col md={9} lg={6} xl={5}>
                        <Image 
                        src="https://firebasestorage.googleapis.com/v0/b/teksto-2f91c.appspot.com/o/assets%2Flogo.png?alt=media&token=3c8705ee-46b2-4978-9abe-890845e3f6dd" 
                        className="img-fluid" 
                        alt="Logo Image" 
                        />
                    </Col>
                    <Col md={8} lg={6} xl={4} className="offset-xl-1">
                        <h3 className="fw-bold mb-3 pb-1">{!isSignUp ? 'Sign-in':'Sign-up'}</h3>
                        <p className="text-muted mb-4 pb-2">
                            By continuing, you agree to our 
                            <span className="link-opacity-100 text-primary" style={{cursor:'pointer'}} onClick={()=>setShow(true)}> Terms and Conditions </span>
                            and acknowledge that you understand the Privacy Policy.
                        </p>
                        <Form className='pb-4'>

                        {isSignUp ?
                        
                        <>
                        <Form.Group className="mb-3" controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your first name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formMiddleName">
                            <Form.Label>Middle Name (Optional)</Form.Label>
                            <Form.Control type="text" placeholder="Enter your middle name" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="form3Example3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)}  />        
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Role</Form.Label>
                            <Form.Select aria-label="Default select example"
                                onChange={(e) => setIsStudent(e.target.value==="0" ? true : false)}
                            >
                                <option value="0">Academic Researcher</option>
                                <option value="1">Academic Facilitator</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="form3Example4">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} />  
                            <Form.Text className="text-muted small">
                                Password must contain at least one uppercase letter, one lowercase letter, and one number.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formConfirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm your password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            
                        </Form.Group>
                        <div className="d-flex justify-content-between align-items-center">
                            {/* Checkbox */}
                            <p className="small mt-2 pt-1 mb-0">
                            Already have an account? <span className="link-opacity-100 text-primary" style={{cursor:'pointer'}} onClick={()=>setIsSignUp(false)}>Sign-in</span>
                            </p>
                            
                        </div>

                        <div className="text-center text-lg-start mt-4 pt-2">
                        <Button variant="primary w-100" className='mb-4' disabled={loading} onClick={signUp}>
                            Sign-up
                        </Button>
                            
                        </div>
                        
                        </>
                        :
                        <>
                        <Form.Group className="mb-4" controlId="form3Example3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)}  />
                                {showResend && <span className="small link-opacity-100 text-primary" style={{cursor:'pointer'}} onClick={resendVerificationEmail}>Resend Verification E-mail</span>}
                        </Form.Group>
                           
                        <Form.Group className="mb-3" controlId="form3Example4">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} />  
                        </Form.Group>

                        <div className="d-flex justify-content-between align-items-center">
                            {/* Checkbox */}
                            <p className="small mt-2 pt-1 mb-0">
                            Don't have an account? <span className="link-opacity-100 text-primary" style={{cursor:'pointer'}} onClick={()=>setIsSignUp(true)}>Sign-up</span>
                            </p>
                            <p className="small mt-2 pt-1 mb-0">
                            <span className="link-opacity-100 text-primary" style={{cursor:'pointer'}} onClick={()=>setShowReset(true)}>Forgot password?</span>
                            </p>
                        </div>

                        <div className="text-center text-lg-start mt-4 pt-2">
                            <Button 
                            variant="primary" 
                            className='w-100'
                            type="button"  
                            disabled={loading} onClick={signIn}    
                            >
                            Sign-in
                            </Button>
                            
                        </div>
                        </>
                        }
                        
                        </Form>
                    </Col>
                    </Row>
                </Container>
                <ModalTerms show={show} setShow={setShow} />
                <ModalReset show={showReset} setShow={setShowReset} />
               
                </section>
        </Container>
        );
}
    
export default Auth;
 