import { format, set, subDays } from "date-fns";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { FIREBASE_DB } from "../config/firebaseConfig";
import { LoadingView } from "../App";
import { Button, Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
const Dashboard = () => {
  const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [visitors, setVisitors] = useState(0);
    const [visitorsYesterday, setVisitorsYesterday] = useState(0);
    const [mostRecentRating, setMostRecentRating] = useState<any|null>();
    const [mostRecentReview, setMostRecentReview] = useState<any|null>();
    const [mostRecentRequest, setMostRecentRequest] = useState<any|null>();
    const [newStudies, setNewStudies] = useState<any[]>();
    const [allStudies, setAllStudies] = useState<any[]>();
    const [mostSavedStudies, setMostSavedStudies] = useState<any[]>();
    const [topRatedStudies, setTopRatedStudies] = useState<any[]>();
    useEffect(() => {
      const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'studies'), 'studies_document'), (snapshot) => {
        let studies = snapshot.data()?.studies_items;
        //@ts-ignore
        setNewStudies(studies.slice(-3));
        setAllStudies(studies);

        const studiesWithAvgRating = studies.map((study: { ratings: any; }) => {
          const ratings = study.ratings;
          const avgRating = ratings.length > 0 
            ? ratings.reduce((sum: any, rating: { rating: any; }) => sum + rating.rating, 0) / ratings.length 
            : 0;
    
          return { ...study, avgRating };
        });
    
        // Sort studies by avgRating in descending order and take the top 5
        const sortedTopRatedStudies = studiesWithAvgRating
          .sort((a: { avgRating: number; }, b: { avgRating: number; }) => b.avgRating - a.avgRating)
          .slice(0, 5);

        console.log("Top Rated Studies:", sortedTopRatedStudies);
    
        setTopRatedStudies(sortedTopRatedStudies);
      });
        
      return () => subscriber();
    }, [])

    useEffect(() => {
      const yesterday = format(subDays(new Date(), 1), 'yyyy-MM-dd');
        async function getDailyVisitors(date:any) {
            const visitDocRef = doc(FIREBASE_DB, 'visits', date);
          
            try {
              const visitDoc = await getDoc(visitDocRef);
              if (visitDoc.exists()) {
                const data = visitDoc.data();
                return data.users.length; // Number of unique users
              } else {
                return 0; // No visits recorded for this date
              }
            } catch (error) {
              console.error('Error retrieving visitors:', error);
              return 0;
            }
          }
          getDailyVisitors(format(new Date(), 'yyyy-MM-dd')).then((visitors) => {
            setVisitors(visitors);

            getDailyVisitors(yesterday).then((visitors) => {
              setVisitorsYesterday(visitors);
              setLoading(false);
            });
         });
    }, []);
    
    useEffect(() => {
      const subscriber = onSnapshot(collection(FIREBASE_DB, 'users'), (snapshot) => {
        let users = snapshot.docs.map(doc => doc.data());
        
        // Aggregate all saved studies
        let studyCount: { [key: string]: number } = {};
        
        users.forEach(user => {
          if (user.savedStudies && Array.isArray(user.savedStudies)) {
            user.savedStudies.forEach(studyId => {
              studyCount[studyId] = (studyCount[studyId] || 0) + 1;
            });
          }
        });
  
        // Sort studies by count and get the top 5
        const topStudies = Object.entries(studyCount)
          .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
          .slice(0, 5) // Take the top 5 studies
          .map(([studyId]) => studyId); // Get only the study IDs
  
        console.log("Top 5 Saved Studies:", topStudies);
        setMostSavedStudies(topStudies);
      });
      
      return () => subscriber();
    }, []);

    useEffect(() => {
      const subscriber = onSnapshot(doc(collection(FIREBASE_DB, 'admin'), 'notifications'), (snapshot) => {
        let notifications = snapshot.data()?.notification_list;
        let recentRating = getMostRecent(notifications,"Rating");
        let recentReview = getMostRecent(notifications,"Review");
        let recentRequest = getMostRecent(notifications,"Request");
        setMostRecentRating(recentRating);
        setMostRecentReview(recentReview);
        setMostRecentRequest(recentRequest);
        console.log("Recent Rating: ", recentRating);
        console.log("Recent Review: ", recentReview);
        console.log("Recent Request: ", recentRequest);
    });
    return () => subscriber();
    }, [])
    function getMostRecent(notifications: any[],status: string) {
      const ratingNotifications = notifications.filter(notification => notification.status === status);
      ratingNotifications.sort((a, b) => (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any));
      return ratingNotifications.slice(0, 3);
    }

    return ( <>
      {loading ? <LoadingView /> :       
        <div className="container" style={{ minHeight: '80vh' }}>
          <h1 className="my-3">Dashboard</h1>
  
          <Row className="mb-4 d-flex align-items-stretch">
            <Col lg={4} md={12} className="mb-4 d-flex">
              <Card className="p-4 w-100 flex-fill">
                <div className="d-flex flex-column align-items-center my-auto">
                  <div className="h5">Total Visitors Today</div>
                  <div className="display-4">{visitors}</div>
                  <div className="text-muted">{visitorsYesterday} visitors yesterday</div>
                </div>
              </Card>
            </Col>
            <Col lg={8} md={12} className="mb-4">
              <Card className="h-100">
                <Card.Header as="h5">Latest Feedbacks</Card.Header>
                <Card.Body>
                  <Card.Subtitle className="text-muted">Most Recent Rating</Card.Subtitle>
                  <Card.Text>
                    {mostRecentRating && mostRecentRating.length > 0 ? (
                      mostRecentRating[0].message
                    ) : (
                      <span className="text-muted">No ratings available</span>
                    )}
                  </Card.Text>

                  <Card.Subtitle className="text-muted">Most Recent Review</Card.Subtitle>
                  <Card.Text>
                    {mostRecentReview && mostRecentReview.length > 0 ? (
                      mostRecentReview[0].message
                    ) : (
                      <span className="text-muted">No reviews available</span>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={12} className="mb-3">
              <Card>
                <Card.Header as="h5">Most Saved Studies</Card.Header>
                <Card.Body>
                  <ListGroup variant="flush" className="">
                    {mostSavedStudies && mostSavedStudies.length > 0 ? (
                      mostSavedStudies.map((studyId: string) => (
                        <ListGroupItem
                          onClick={() => navigate(`/study/${studyId}`)}
                          key={studyId}
                          style={{
                            cursor: "pointer",
                            transition: "background-color 0.2s ease-in-out",
                            padding: "10px",
                          }}
                          onMouseEnter={(e) => {
                            (e.currentTarget.style.backgroundColor = "#f0f0f0");
                            e.currentTarget.style.color = "#333";
                          }}
                          onMouseLeave={(e) => {
                            (e.currentTarget.style.backgroundColor = "");
                            e.currentTarget.style.color = "";
                          }}
                        >
                          {allStudies?.find(study => study.id === studyId)?.title}
                        </ListGroupItem>
                      ))
                    ) : (
                      <p className="text-muted">No Saved Studies Yet</p>
                    )}
                  </ListGroup>
                </Card.Body>

              </Card>
            </Col>
            
            <Col lg={6} md={12}>
              <Card>
                <Card.Header as="h5">Top 5 Rated Studies</Card.Header>
                <Card.Body>
                  <ListGroup variant="flush">
                    {topRatedStudies && topRatedStudies.length > 0 ? (
                      topRatedStudies.map((study: any) => (
                        <ListGroupItem key={study.id}
                          onClick={() => navigate(`/study/${study.id}`)}
                          style={{
                            cursor: "pointer",
                            transition: "background-color 0.2s ease-in-out",
                            padding: "10px",
                          }}
                          onMouseEnter={(e) => {
                            (e.currentTarget.style.backgroundColor = "#f0f0f0");
                            e.currentTarget.style.color = "#333";
                          }}
                          onMouseLeave={(e) => {
                            (e.currentTarget.style.backgroundColor = "");
                            e.currentTarget.style.color = "";
                          }}
                        >
                          {study.title} - {study.avgRating.toFixed(1)}
                        </ListGroupItem>
                      ))
                    ) : (
                      <p className="text-muted">No Rated Studies Yet</p>
                    )}
                  </ListGroup>
                 
                </Card.Body>
              </Card>
            </Col>
          </Row>
  
          <Row className="mb-4">
            <Col lg={6} md={12} className="mb-3">
              <Card>
                <Card.Header as="h5">Latest Requests</Card.Header>
                <Card.Body>
                  <ListGroup variant="flush">
                    {mostRecentRequest && mostRecentRequest.length > 0 ? (
                      mostRecentRequest.map((request: any) => (
                        <ListGroupItem key={request.notification_id}>{request.message}</ListGroupItem>
                      ))
                    ) : (
                      <p className="text-muted">No Requests Yet</p>
                    )}
                  </ListGroup>
                  <Button variant="primary" className="mt-2" onClick={() => navigate('/requests')}>
                    View All Requests
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            
            <Col lg={6} md={12}>
              <Card>
                <Card.Header as="h5">Newly Added Studies</Card.Header>
                <Card.Body>
                  <ListGroup variant="flush">
                    {newStudies && newStudies.length > 0 ? (
                      newStudies.map((study: any) => (
                        <ListGroupItem key={study.id}>{study.title}</ListGroupItem>
                      ))
                    ) : (
                      <p className="text-muted">No Studies Yet</p>
                    )}
                  </ListGroup>
                  <Button variant="primary" className="mt-2" onClick={() => navigate('/resources')}>
                    View All Studies
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
  

        </div>
      }
    </> );
}
 
export default Dashboard;