import { Accordion, Container, Card, Button, Image, FloatingLabel, Form, Modal } from "react-bootstrap";
import { FIREBASE_AUTH, FIREBASE_DB, FIREBASE_STORAGE } from "../config/firebaseConfig";
import { SetStateAction, useEffect, useState } from "react";
import { sendEmailVerification, updateEmail, updatePassword, updateProfile, User } from "firebase/auth";
import toast from "react-hot-toast";
import { FaCamera } from "react-icons/fa";
import { getDownloadURL, getStorage, ref, uploadBytesResumable, uploadString } from "firebase/storage";
import { collection, doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";

interface SettingsProps {
  isAdmin: boolean;
  isSuperDuperAdmin: boolean;
}
const Settings: React.FC<SettingsProps> = ({ isAdmin, isSuperDuperAdmin }) => {
  const user = FIREBASE_AUTH.currentUser;
  const [photoURL, setPhotoURL] = useState(user?.photoURL || '');
  const [name, setName] = useState(user?.displayName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [password, setPassword] = useState('');
  const [bio, setBio] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [userData, setUserData] = useState<any | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSelectedImage(null);
    setText("");
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    const subscriber = onSnapshot(
      doc(collection(FIREBASE_DB, 'users'), user?.uid as string),
      (snapshot) => {
        const data = snapshot.data();
        setUserData(data);
        setBio(data?.bio || '');
      }
    );
    return () => subscriber();
  }, [user?.uid]);

  async function updatePhotoURL() {
    if (photoURL === user?.photoURL || !file) return;

    const storageRef = ref(FIREBASE_STORAGE, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error("Upload failed:", error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log("File available at", downloadURL);
        updateProfile(user as User, { photoURL: downloadURL })
          .then(() => {
            toast.success('Avatar updated successfully');
          })
          .catch((error) => {
            console.log(error);
            toast.error('An error occurred');
          });
        setPhotoURL(downloadURL);
      }
    );
  }

  const uploadPicture = (): void => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async () => {
      const file = input.files?.item(0);
      if (file) {
        console.log(file);

        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const preview = e.target?.result as string;
          setPhotoURL(preview);
          setFile(file);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  function updateName() {
    if (name === user?.displayName) {
      toast.error('Name is the same as the current one');
      return;
    }
    updateProfile(user as User, { displayName: name })
      .then(() => {
        toast.success('Name updated successfully');
      })
      .catch((error) => {
        console.log(error);
        toast.error('An error occurred');
      });
  }

  function changeEmail() {
    if (email === user?.email) {
      toast.error('Email is the same as the current one');
      return;
    }
    updateEmail(user as User, email)
      .then(() => {
        sendEmailVerification(user as User)
          .then(async () => {
            const docRef = doc(FIREBASE_DB, 'users', user?.uid as string);
            await updateDoc(docRef, { email });
            toast.success('Email updated successfully');
          })
          .catch((error) => {
            console.log(error.message);
            toast.error('An error occurred, Please make sure your email is valid');
          });
      })
      .catch((error) => {
        console.log(error.message);
        toast.error('An error occurred, Please make sure your email is valid');
      });
  }

  function changePassword() {
    updatePassword(user as User, password)
      .then(() => {
        toast.success('Password updated successfully');
      })
      .catch((error) => {
        console.log(error.message);
        toast.error('An error occurred, Make sure your password is at least 6 characters long');
      });
  }

  function changeBio() {
    const docRef = doc(FIREBASE_DB, 'users', user?.uid as string);
    updateDoc(docRef, { bio })
      .then(() => {
        toast.success('Bio updated successfully');
      })
      .catch((error) => {
        console.log(error);
        toast.error('An error occurred');
      });
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;
    const file = files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setSelectedImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleTextChange = (e: { target: { value: SetStateAction<string>; }; }) => {
    setText(e.target.value);
  };
  const uploadID = async (selectedImage: string) => {
    try {
      // Initialize Firebase storage reference
      const storage = getStorage();
      const storageRef = ref(storage, `request_ids/${user?.uid}_photoID.jpg`); // Create unique file name with timestamp
  
      // Upload the image (Base64 string) to the storage folder 'request_ids'
      const snapshot = await uploadString(storageRef, selectedImage, 'data_url');
  
      // Get the download URL for the uploaded image
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL; // Return the download link
    } catch (error) {
      console.error("Error uploading ID:", error);
      throw new Error("Unable to upload ID");
    }
  };
  const handleRequest = async () => {
  
    if (!selectedImage) {
      toast.error("Please upload your ID");
      return;
    }
  
    try {
      setIsLoading(true);
      const idLink = await uploadID(selectedImage as string);
  
      if (user?.uid) {
        await setDoc(doc(FIREBASE_DB, "adminrequests", user.uid), {
          text,
          photoID: idLink,
          status: "pending",
          createdAt: new Date(),
          userId: user.uid,
          userEmail: user.email,
          userName: user.displayName,
          userPhotoURL: user.photoURL,
        });
        toast.success("Request sent successfully");
        handleClose();
      } else {
        toast.error("User ID is not available");
      }
    } catch (error) {
      toast.error("Failed to upload ID");
      console.error(error);
    }
    setIsLoading(false);
  };
  
  return (
    <Container fluid="sm" className="mb-5" style={{minHeight: '80vh'}}>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Admin Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Request Information</Form.Label>
            <Form.Control 
              as="textarea" 
              rows={3} 
              value={text} 
              onChange={handleTextChange} 
            />
          </Form.Group>

          <Form.Group controlId="formFile">
            <Form.Label>Upload ID</Form.Label>
            <Form.Control 
              type="file" 
              accept="image/*" 
              onChange={(e) => handleImageChange(e as React.ChangeEvent<HTMLInputElement>)} 
            />
          </Form.Group>

          {selectedImage && (
            <div className="mt-3">
              <Image src={selectedImage} thumbnail />
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" disabled={!selectedImage || !text || isLoading} onClick={handleRequest}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
       <div className='flex-row d-flex justify-content-between w-100 align-items-center'>
          <h2>Settings</h2>
          {userData && !userData.isStudent && !isSuperDuperAdmin && !isAdmin &&
            <div>
              <Button variant="primary" size="sm" onClick={handleShow}>Request Admin</Button>
            </div>
          }       
      </div>

      <Card className="my-3">
        <Card.Header as="h5">Avatar</Card.Header>
        <Card.Body className="d-flex justify-content-between">
          <div className="image-container" onClick={() => uploadPicture()}>
            <Image
              src={photoURL.includes('googleusercontent') ? photoURL.replace(/s\d+-c/, `s${256}-c`) : photoURL || ''}
              alt="avatar"
              className="rounded-circle img-fluid"
              style={{ width: '150px' }}
            />
            <div className="hover-overlay">
              <FaCamera className="camera-icon" />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Button variant="primary" onClick={updatePhotoURL}>Update Avatar</Button>
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header as="h5">Change Name</Card.Header>
        <Card.Body className="d-flex flex-column">
          {user?.providerData[0].providerId === 'google.com' ? (
            <p className="text-danger">
              You are signed in using your Google account, you can't change your name.
            </p>
          ) : (
            <>
              <FloatingLabel label="New Name" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Erika Hernandez"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FloatingLabel>
              <Button
                variant="primary"
                size="sm"
                className="align-self-end"
                onClick={() => updateName()}
              >
                Update Name
              </Button>
            </>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Header as="h5">Change E-mail</Card.Header>
        <Card.Body className="d-flex flex-column">
          {user?.providerData[0].providerId === 'google.com' ? (
            <p className="text-danger">
              You are signed in using your Google account, you can't change your email.
            </p>
          ) : (
            <>
              <FloatingLabel label="New E-mail" className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FloatingLabel>
              <Button
                variant="primary"
                size="sm"
                className="align-self-end"
                onClick={() => changeEmail()}
              >
                Update E-mail
              </Button>
            </>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Header as="h5">Change Password</Card.Header>
        <Card.Body className="d-flex flex-column">
          {user?.providerData[0].providerId === 'google.com' ? (
            <p className="text-danger">
              You are signed in using your Google account, you can't change your password.
            </p>
          ) : (
            <>
              <FloatingLabel label="New Password" className="mb-3">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FloatingLabel>
              <Button
                variant="primary"
                size="sm"
                className="align-self-end"
                onClick={() => changePassword()}
              >
                Update Password
              </Button>
            </>
          )}
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Header as="h5">Change Bio</Card.Header>
        <Card.Body className="d-flex flex-column">
          <FloatingLabel label="Tell us about yourself" className="mb-3">
            <Form.Control
              type="text"
              placeholder="No information."
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
          </FloatingLabel>
          <Button
            variant="primary"
            size="sm"
            className="align-self-end"
            onClick={() => changeBio()}
          >
            Update Bio
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Settings;
